import { useFormContext, Controller } from 'react-hook-form'
import { FormField, FormControl, FormFieldInline } from '@wiz/components'
import SelectTwin from '@/components/Form/SelectTwin'
import { get, consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import ButtonCreateTwin from './ButtonCreateTwin'

export default function Fields ({ scope, id }) {
  const auth = useAuth()
  const intl = useIntl()
  const { formState: { errors }, setValue } = useFormContext()

  const handleCreateTwinSuccess = (res) => {
    console.log('boom', res)
    setValue(`${scope}.twinId`, res.id)
  }

  return (
    <>
      <FormField
        label={intl.t('form.fields.deviceId')}
        description={intl.t('edgeDevices.form.fields.deviceIdDescr')}
        errors={get(errors, `${scope}.key`)}
      >
        <FormControl
          name={`${scope}.deviceId`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
            maxLength: {
              value: 32,
              message: intl.t('form.errors.fieldMaxlen', { max: 32 }),
            },
          }}
          disabled={id}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.name')}
        description={intl.t('edgeDevices.form.fields.nameDescr')}
        errors={get(errors, `${scope}.name`)}
      >
        <FormControl
          name={`${scope}.name`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
          }}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.twin')}
        description={intl.t('edgeDevices.form.fields.twinDescr')}
        errors={get(errors, `${scope}.twinId`)}
      >
        <Controller
          name={`${scope}.twinId`}
          render={({ field, fieldState }) => (
            <SelectTwin
              {...field}
              invalid={fieldState.invalid}
              placeholder={intl.t('form.fields.twinPlaceholder')}
              filters={{
                types: [
                  consts.TwinType.Area,
                  consts.TwinType.Equipment,
                  consts.TwinType.Machine,
                  consts.TwinType.Flow,
                ],
              }}
              postfix={auth.checkAccessManage('Twin') ? (
                <ButtonCreateTwin
                  onSuccess={handleCreateTwinSuccess}
                />
              ) : null}
            />
          )}
        />
      </FormField>
    </>
  )
}

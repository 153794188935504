import { useRef } from 'react'
import classnames from 'classnames'
import {
  useFormContext,
} from 'react-hook-form'
import { has } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import {
  Dropdown,
} from '@wiz/components'
import { severities } from './utils'
import s from './index.module.css'

const SelectSeverity = ({ scopeRow }) => {
  const refDropdown = useRef()
  const refTarget = useRef()
  const intl = useIntl()
  const {
    formState: { errors }, watch, setValue,
  } = useFormContext()

  const severityId = watch(`${scopeRow}.severity`)

  const status = severities.find(sev => sev.id === Number(severityId))
  return (
    <>
      <button
        ref={refTarget}
        name={intl.t('insights.form.fields.severityPlaceholder')}
        title={intl.t('insights.form.fields.severityPlaceholder')}
        type="button"
        className={classnames(s.select, 'form-select', {
          'is-invalid': has(errors, `${scopeRow}.severity`),
        })}
      >
        {status ? (
          <>
            <span className={classnames('me-2', s.status)} style={{ backgroundColor: status.color }} />
            {intl.t(`insights.form.fields.severity.${status.value}`)}
          </>
        ) : intl.t('insights.form.fields.severityPlaceholder')}
      </button>
      <Dropdown
        arrow
        autoclose
        ref={refDropdown}
        target={refTarget}
        placement="bottom"
        className="py-2 text-break"
        width={refTarget.current?.offsetWidth}
      >
        {severities.map(({ id, value, color }) => (
          <div
            key={id}
            id={id}
            className={classnames('py-1 px-2 d-flex align-items-center', s.item, {
              [s.itemActive]: severityId === id,
            })}
            aria-hidden
            onClick={() => {
              setValue(`${scopeRow}.severity`, id, { shouldDirty: true })
              refDropdown.current?.close()
            }}
          >
            <span className={classnames('me-2', s.status)} style={{ backgroundColor: color }} />
            {intl.t(`insights.form.fields.severity.${value}`)}
          </div>
        ))}

      </Dropdown>
    </>
  )
}

export default SelectSeverity

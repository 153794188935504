import cx from 'classnames'
import { useMobile } from '@wiz/components'
import { useLocation, useLocationQuery } from '@/router'
import { CorePages as PAGES, PagesMap } from '@/utils/consts'
import OperateTopbar from '@/pages/operate/topbar'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import Actions from './actions'
import SinglePipelineActions from './actions/singlePipeline'
import ExperimentActions from './actions/experiment'
import EdgeDeviceActions from './actions/edgeDevice'
import Filters from './filters'
import classes from './index.module.css'
import Breadcrumbs from './breadcrumbs'

const TopBar = ({
  isFiltersOpen, onAdd, onSidebarOpen, onFiltersOpen,
}) => {
  const { contextTwinId } = useGlobalExecute()
  const { pipelineId } = useLocationQuery()
  const isMobile = useMobile()
  const loc = useLocation()
  const [ ,, corePage, currentPage, id ] = loc.pathname.split('/')

  const handleAdd = (...params) => {
    if (!params[0]) {
      if (currentPage === 'templates' && id) {
        onAdd('registrations', false, id) // edge case to handle registrations form
      } else {
        onAdd(currentPage)
      }
    } else {
      onAdd(...params)
    }
  }

  const renderActionBar = () => {
    switch (true) {
      case corePage === PAGES.OPERATE:
        return (
          <OperateTopbar
            isFiltersOpen={isFiltersOpen}
            onSidebarOpen={onSidebarOpen}
            onFiltersOpen={onFiltersOpen}
            tree={contextTwinId}
          />
        )
      case currentPage === PagesMap.Pipelines && !!pipelineId:
        return <SinglePipelineActions onRun={handleAdd} />
      case currentPage === PagesMap.EdgeDevices && !!id:
        return <EdgeDeviceActions />
      case id && currentPage !== PagesMap.Templates:
        return null
      default:
        return (
          <>
            <Actions onAdd={handleAdd} />
            {currentPage !== PagesMap.Twins && (
              <Filters common={corePage === PAGES.DESIGN} />
            )}
          </>
        )
    }
  }

  return (
    <header
      className={cx('align-items-start pe-3 border-y', classes.header, {
        [classes.headerMobile]: isMobile,
      })}
    >
      {isMobile ? null : <Breadcrumbs />}
      <div className={cx('d-flex align-items-center position-relative', {
        'w-100': isMobile,
      })}
      >
        {renderActionBar()}
        {currentPage === PagesMap.Experiments && id ? <ExperimentActions onEdit={handleAdd} onRun={handleAdd} /> : null}
      </div>
    </header>
  )
}

export default TopBar

import { forwardRef, useMemo } from 'react'

import { consts } from '@wiz/utils'
import TwinList from '@/hoc/TwinList'
import Select from '@/components/Form/Select'
import ListTwins from '@/components/Form/ListTwins'
import enhance from '@/hoc/Templates'
import { withObservables } from '@wiz/components'
import { dbProvider, Q } from '@wiz/store'
import { of } from 'rxjs'

const enhanceTwinsData = withObservables([ 'twinIds' ], ({ twinIds }) => ({
  options: twinIds ? dbProvider.database.collections.get('twins')
    .query(Q.where('id', Q.oneOf(twinIds)))
    .observeWithColumns([ 'updated_at' ]) : of(undefined),
}))

const DefaultFiltersTypes = [
  consts.TwinType.Area,
  consts.TwinType.Equipment,
  consts.TwinType.Machine,
]

const SelectTwin = forwardRef(({
  keyName = 'id',
  filters,
  options,
  isLoading,
  showSelectedCount = false,
  ...props
}, ref) => {
  const currentFilters = useMemo(() => ({
    types: DefaultFiltersTypes,
    ...filters,
  }), [ filters ])

  return (
    <Select
      ref={ref}
      keyName={keyName}
      filters={currentFilters}
      disabled={isLoading}
      isLoading={isLoading}
      showSelectedCount={showSelectedCount}
      {...(!options ? {
        ListSource: false,
        ListController: ListTwins,
        BadgeListSource: TwinList,
      } : { options }
      )}
      {...props}
    />
  )
})

export default enhance(enhanceTwinsData(SelectTwin))

import { useFormContext, Controller } from 'react-hook-form'
import { FormField, FormControl, FormFieldInline } from '@wiz/components'
import { get, consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

export default function Fields ({ scope, id }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <FormFieldInline
      label={intl.t('form.fields.installedPackages')}
      description={intl.t('form.fields.installedPackagesDescr')}
      errors={get(errors, `${scope}.packages`)}
      vertical
      complex
    >
      <FormControl
        type="code"
        lang="json"
        title={intl.t('form.fields.installedPackages')}
        description={intl.t('form.fields.installedPackagesDescr')}
        name={`${scope}.packages`}
        disabled
        rules={{
          validate: (value) => {
            const str = String(value || '').trim()
            if (!str.length) {
              return intl.t('form.errors.fieldRequired')
            }
            if (value?.length > 20000) {
              return intl.t('form.errors.fieldMaxlen', { max: 20000 })
            }
            return true
          },
        }}
      />
    </FormFieldInline>
  )
}

import {
  useCallback, useEffect, useMemo,
} from 'react'
import cx from 'classnames'
import camelCase from 'lodash/camelCase'
import { auth, useAuth } from '@/auth'
import { Icon as LegacyIcon } from '@wiz/components'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import { useRouter, useLocationQuery, useLocation } from '@/router'
import Icon from '@/shared/icon'
import { useIntl } from '@wiz/intl'
import useAppContext from '@/hooks/useAppContext'
import { BOARD_VIEW_ENUM, SEVERITY_ENUM } from '@/utils/consts'
import classes from '../index.module.css'

const OperateSideNav = () => {
  const {
    dashboardsComponentsDeepList,
    labels,
    isEditing,
    handleEdit,
    operateFilters,
    severitiesMapped,
    severities,
  } = useAppContext()

  const { selectedComponent, setSelectedComponent, contextTwinId } = useGlobalExecute()

  const intl = useIntl()
  const router = useRouter()
  const query = useLocationQuery()
  const loc = useLocation()
  const { currentUser } = useAuth()
  const [ ,,, currentPage ] = loc.pathname.split('/')

  const filteredOptions = useMemo(() => dashboardsComponentsDeepList?.data?.filter((item) => {
    let isMatch = true
    if (operateFilters.boardView && operateFilters.boardView !== BOARD_VIEW_ENUM.GLOBAL) {
      if (operateFilters.boardView === BOARD_VIEW_ENUM.PERSONAL) {
        isMatch = item.ownerId === currentUser.id
      } else if (operateFilters.boardView === BOARD_VIEW_ENUM.ORGANIZATIONAL) {
        isMatch = !item.ownerId
      } else {
        isMatch = item.ownerId === currentUser.id || !item.ownerId
      }
    }
    if (operateFilters.isShowFavorites) {
      isMatch = item.isFavorite
    }
    if (Object.values(severities).length && operateFilters.severity && operateFilters.severity !== SEVERITY_ENUM.ALL) {
      isMatch = severitiesMapped[operateFilters.severity]?.includes(item.id)
    }
    return isMatch
  }), [ dashboardsComponentsDeepList?.data,
    operateFilters,
    currentUser.id,
    severitiesMapped,
    severities ])

  const isOwn = useMemo(() => {
    if (filteredOptions?.length) {
      const twin = filteredOptions?.find(opt => opt.twinId === contextTwinId)
      return !!twin
    }
    return false
  }, [ filteredOptions, contextTwinId ])

  const categories = useMemo(
    () => filteredOptions?.reduce((acc, val) => {
      if (!contextTwinId || !isOwn) {
        return []
      }
      const label = labels?.find(l => l.id === val.labelId)

      const subCat = acc.find(item => item.labelId === val.labelId)
      const isChild = val?.twinId === contextTwinId

      if (!subCat && isChild && (!val.ownerId || (!!val.ownerId && val.ownerId === currentUser.id))) {
        acc.push({
          ...val,
          label: label?.name,
          value: val.id,
          icon: label?.icon?.includes('--') ? label?.icon : camelCase(label?.icon),
          order: label?.order,
          color: label?.color,
        })
      }

      return acc
    }, []).sort((a, b) => a.order - b.order) || [],
    [ filteredOptions, labels, contextTwinId, isOwn, currentUser.id ],
  )

  // useEffect(() => {
  //   if (categories && selectedComponent?.category !== query.category) {
  //     const categoryIds = categories.map(cat => cat.labelId)
  //     if (query.sub && query.category && selectedComponent?.category) {
  //       router.push({
  //         query: {
  //           ...query,
  //           category: !categoryIds.includes(selectedComponent?.category) ?
  //             categoryIds[0] :
  //             selectedComponent?.category,
  //           tree: null,
  //           contextTwinId: null,
  //         },
  //       })
  //     }
  //   }
  // }, [ selectedComponent?.category, categories ])

  const getFirstDashboard = useCallback(
    labelId => filteredOptions
      .sort((a, b) => a.order - b.order)
      .find(g => g.labelId === labelId),
    [ filteredOptions ],
  )

  const handleRightBarClick = useCallback(async (labelId) => {
    if (isEditing && labelId !== query.category) {
      await window.wizConfirm({ message: 't/components.leaveEdit' })
      handleEdit(false)
    }
    const firstDashboard = getFirstDashboard(labelId)
    setSelectedComponent({ ...selectedComponent, category: firstDashboard.labelId, dashboard: firstDashboard })
    router.push({
      name: 'controlpanel',
      query: {
        ...query, contextTwinId, category: labelId, sub: firstDashboard?.id,
      },
    })
  }, [
    query,
    router,
    contextTwinId,
    isEditing,
    handleEdit,
    selectedComponent,
    setSelectedComponent,
    getFirstDashboard,
  ])

  const handleLinkClick = useCallback(async () => {
    if (isEditing) {
      await window.wizConfirm({ message: 't/components.leaveEdit' })
      handleEdit(false)
    }
    router.push({
      name: 'controlpanel',
      query: { category: 'search', tree: contextTwinId },
    })
  }, [
    router,
    contextTwinId,
    isEditing,
    handleEdit,
  ])

  const handleExplorerClick = useCallback(async () => {
    if (isEditing) {
      await window.wizConfirm({ message: 't/components.leaveEdit' })
      handleEdit(false)
    }
    router.push({ name: 'de', params: { id: 'reset' } })
  }, [
    router,
    isEditing,
    handleEdit,
  ])

  return (
    <>
      <a
        onClick={handleLinkClick}
        aria-hidden
        className={cx(
          'btn btn-secondary-alt py-2 ps-3 d-flex justify-content-start',
          classes.menuItem,
          { active: (!query.category || query.category === 'search' || !contextTwinId) && !currentPage },
        )}
        title={intl.t('form.actions.overview')}
      >
        <Icon name="faLocationDot" size="lg" className="me-2" />
        <span className="mt-1 fs-6">
          {intl.t('form.actions.overview')}
        </span>
      </a>

      {categories?.map(({
        id, label, labelId, icon, color,
      }) => {
        let isLegacyIcon = true
        if (icon?.includes('--')) {
          isLegacyIcon = false
        }
        return (
          <a
            key={id}
            aria-hidden
            className={cx(
              'btn btn-secondary-alt py-2 ps-3 d-flex justify-content-start',
              classes.menuItem,
              { active: labelId === query.category },
            )}
            onClick={() => handleRightBarClick(labelId)}
            title={intl.t(label)}
          >
            {isLegacyIcon ? <Icon name={icon} color={color} size="sm" className="me-2" /> : (
              <LegacyIcon
                name={icon || 'placeholder'}
                color={color}
                width={11}
                height={11}
                className="me-2"
              />
            )}
            <span
              className="mt-1 fs-6 text-truncate"
              style={{
                textAlign: 'left',
                wordBreak: 'break-word',
              }}
            >
              {intl.t(label) || 'Others'}
            </span>
          </a>
        )
      })}
      {auth.checkAccessManage('SectionControlPanelDataExplorer') ?
        (
          <div
            className={cx(
              'btn btn-secondary-alt py-2 ps-3 d-flex justify-content-start',
              classes.menuItem,
              { active: currentPage === 'de' },
            )}
            onClick={handleExplorerClick}
            aria-hidden
          >
            <LegacyIcon name="wiz--menu--data-explorer" size="lg" width={11} className="me-2" />
            <span className="mt-1 fs-6">
              {intl.t('menu.title.explorer')}
            </span>
          </div>
        ) : null}
    </>
  )
}

export default OperateSideNav

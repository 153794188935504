import {
  forwardRef,
  useRef,
  useCallback,
  useMemo,
  useState,
} from 'react'
import classnames from 'classnames'
import { toArrayValue } from '@wiz/utils'
import { Icon } from '@wiz/components'
import BadgeList from './BadgeList'
import DefaultListSource from './DefaultListSource'
import Dropdown from './Dropdown'
import classes from './index.css'

const Select = forwardRef(({
  BadgeListContent,
  BadgeListSource,
  children,
  className,
  disabled,
  readOnly,
  displayCount,
  filters,
  initialStyle,
  initialOptionStyle,
  invalid,
  keyName = 'id',
  ListContent,
  ListController,
  ListSource = DefaultListSource,
  multiselect,
  showSelectedCount,
  name,
  onBlur,
  onChange,
  options,
  placeholder,
  postfix,
  prefix,
  controlPrefix,
  size,
  title,
  value,
  clearable = true,
  isGlobalTwins = false,
  withCheckboxes = false,
  collection,
  listProps,
  isLoading,
  noRemove,
  ...props
}, ref) => {
  const refTarget = useRef()
  const refDropdown = useRef()
  const [ optionsList, setOptionsList ] = useState([])
  const DataBadgeList = useMemo(() => (
    BadgeListSource ? BadgeListSource(BadgeList) : ListSource(BadgeList)
  ), [ ListSource, BadgeListSource ])

  const handleRemove = useCallback((key) => {
    let next = null
    if (multiselect) {
      next = toArrayValue(value).filter(item => item !== key)
    }
    onChange?.(next)
  }, [ multiselect, value, onChange ])

  const handleChange = useCallback((option) => {
    let next = null
    if (multiselect) {
      next = toArrayValue(value)
      if (option === null) {
        next = []
      } else if (next.includes(option[keyName])) {
        next = next.filter(item => item !== option[keyName])
      } else {
        next = next.concat(option[keyName])
      }
    } else {
      refDropdown.current.close()
      next = option?.[keyName] ?? null
    }
    onChange?.(next)
  }, [ multiselect, value, keyName, onChange ])

  const handleToggleAll = useCallback(() => {
    if (optionsList.length === value?.length) {
      onChange?.([])
    } else {
      const toggleIds = optionsList.map(item => item.id)
      onChange?.(toggleIds)
    }
  }, [ value, onChange, optionsList ])

  const control = (
    <button
      ref={refTarget}
      name={name}
      title={title}
      type="button"
      className={classnames(classes.root, className, {
        'form-select': !initialStyle,
        'form-select-sm': size === 'sm' && !initialStyle,
        'is-invalid': !!invalid,
      })}
      disabled={disabled || readOnly}
      onBlur={onBlur}
    >
      {isLoading ? (
        <Icon
          className={classes.loading}
          name="fa--spinner"
          spin
        />
      ) : null}
      {controlPrefix ? (
        <div className="fw-bold">
          {controlPrefix}
          :&nbsp;
        </div>
      ) : null}
      {children || (
        <DataBadgeList
          {...filters}
          keyName={keyName}
          displayCount={displayCount}
          disabled={disabled}
          readOnly={readOnly}
          placeholder={placeholder}
          selectedOnly
          options={options}
          value={value}
          Content={BadgeListContent}
          initialStyle={initialOptionStyle}
          onRemove={clearable ? handleRemove : undefined}
          collection={collection}
          noRemove={noRemove}
        />
      )}
    </button>
  )
  console.log(postfix)
  return (
    <>
      {postfix || prefix ? (
        <div className="input-group">
          {prefix}
          {control}
          {postfix}
        </div>
      ) : control}

      <Dropdown
        {...props}
        {...listProps}
        ref={refDropdown}
        refTarget={refTarget}
        filters={filters}
        keyName={keyName}
        value={value}
        options={options}
        ListSource={ListSource}
        ListContent={ListContent}
        ListController={ListController}
        clearable={clearable}
        onChange={handleChange}
        onToggleAll={handleToggleAll}
        isGlobalTwins={isGlobalTwins}
        withCheckboxes={withCheckboxes}
        multiselect={multiselect}
        showSelectedCount={showSelectedCount}
        optionsList={optionsList}
        getOptions={setOptionsList}
      />
    </>
  )
})

export default Select

import { useState, useRef } from 'react'
import {
  useLocation,
} from '@/router'
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query'
import events from '@/utils/events'
import { wizataApi } from '@/api'
import { useIntl } from '@wiz/intl'
import Icon from '@/shared/icon'
import { EdgeDeviceStatus } from '@/utils/consts'
import { Skeleton, Icon as LegacyIcon, Popup } from '@wiz/components'
import Link from '../../../../components/Link'

const EdgeDeviceActions = () => {
  const [ isPopupOpened, setIsPopupOpened ] = useState(false)
  const [ isContinueClicked, setIsContinueClicked ] = useState(false)
  const refDialog = useRef()

  const intl = useIntl()
  const loc = useLocation()

  const [ ,,, currentPage, edgeDeviceId ] = loc.pathname.split('/')

  const { data, isLoading } = useQuery({
    queryKey: [ 'edgeDeviceConnectionString', edgeDeviceId ],
    queryFn: () => wizataApi.edgeDevices.getConnection(edgeDeviceId),
    enabled: !!isPopupOpened,
  })

  const { data: item, isLoading: edgeDeviceLoading } = useQuery({
    queryKey: [ 'singleEdgeDevice', edgeDeviceId ],
    queryFn: () => wizataApi.edgeDevices.getById(edgeDeviceId),
    enabled: false,
  })

  const registrationStatus = useQuery({
    queryKey: [ 'singleEdgeDeviceRegistrationStatus', edgeDeviceId ],
    queryFn: () => wizataApi.edgeDevices.getStatus(edgeDeviceId),
    enabled: false,
  })

  const handleCopyClick = async () => {
    await navigator.clipboard.writeText(data.connectionString)
    events.emit('app:notify', {
      type: 'success',
      title: 't/edgeDevices.copiedConnectionString',
      duration: 1,
      rawMessage: 't/edgeDevices.copiedConnectionStringMessage',
    })
  }

  const handleClickContinue = () => {
    setIsContinueClicked(true)
    setIsPopupOpened(false)
  }

  const handleClickRefrech = () => {
    registrationStatus?.refetch()
  }

  const isRegistrationStatusLoading = registrationStatus.isLoading && registrationStatus.isFetching

  const isWaiting = item?.status === EdgeDeviceStatus.Waiting

  return (
    <>
      <button
        type="button"
        className="btn btn-fill-secondary-alt btn-text me-2"
        onClick={() => setIsPopupOpened(!isPopupOpened)}
      >
        <Icon name="faEye" className="me-2" />
        {intl.t('edgeDevices.view.connectionString')}
      </button>

      <button
        type="button"
        className="btn btn-fill-secondary-alt btn-text me-2"
        onClick={handleClickRefrech}
        disabled={isRegistrationStatusLoading}
      >
        <Icon
          name={isRegistrationStatusLoading ? 'faSpinner' : 'faArrowsRotate'}
          className="me-2"
          spin={isRegistrationStatusLoading}
        />
        {intl.t('form.actions.refresh')}
      </button>

      <div className="fw-bold my-auto">
        {edgeDeviceLoading ? <Skeleton width={120} height={30} /> : (
          <>
            <LegacyIcon name="wiz--menu--edge-devices" className="me-1" />
            {item?.deviceId}
          </>
        )}
      </div>

      {isPopupOpened ? (
        <Popup
          refDialog={refDialog}
          title="Your connection string"
          size="lg"
          customStyles={{ maxWidth: '720px' }}
          onClose={() => setIsPopupOpened(false)}
        >
          <div className="d-flex align-items-center mb-3">
            <input
              className="form-control"
              type="text"
              defaultValue={data?.connectionString}
            />
            <button
              className="btn me-3"
              type="button"
              onClick={handleCopyClick}
            >
              <Icon className="me-2" name="faFiles" width={20} height={20} />
            </button>
          </div>
          <div className="d-flex align-items-center small mb-4 bg-light-alt p-2 rounded-1">
            <Icon className="me-2 link-primary" name="faCircleExclamation" width={10} height={10} />
            <span>Use the connection string on your Edge Device to finish your configuration and then click Refresh.</span>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <span>
              <Icon className="me-2" name="faFileCode" width={10} height={10} />
              For more information:
              &nbsp;
              <a href="https://docs.wizata.com/v11.1/docs/register-and-setup-an-edge-device#register-your-device" target="_blank" rel="noreferrer">Connect Wizata Edge Device</a>
            </span>
            <button
              className="btn btn-fill-secondary-alt ms-3"
              type="button"
              onClick={handleClickContinue}
            >
              Continue
            </button>
          </div>
        </Popup>
      ) : null}
    </>

  )
}

export default EdgeDeviceActions

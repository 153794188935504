export default function edgeDevices (client) {
  return function decorator (target) {
    Object.defineProperties(target.prototype, {
      edgeDevices: {
        enumerable: true,
        value: Object.create({}, {
          getList: {
            enumerable: true,
            value (params) {
              const request = client.post('/EdgeDevices/paged', { ...params })
              return request.fetch()
            },
          },
          getById: {
            enumerable: true,
            value (id) {
              return client.get(`/EdgeDevices/${id}`).fetch()
            },
          },
          deleteById: {
            enumerable: true,
            value (id) {
              return client.delete(`/EdgeDevices/${id}`).fetch()
            },
          },
          create: {
            enumerable: true,
            value (params) {
              return client.post('/EdgeDevices', { ...params }).fetch()
            },
          },
          update: {
            enumerable: true,
            value (id, { ...params }) {
              return client.put(`/EdgeDevices/${id}`, { ...params }).fetch()
            },
          },
          getConnection: {
            enumerable: true,
            value (id) {
              return client.get(`/EdgeDevices/${id}/connection`).fetch()
            },
          },
          getStatus: {
            enumerable: true,
            value (id) {
              return client.post(`/EdgeDevices/${id}/status`).fetch()
            },
          },
          deployModules: {
            enumerable: true,
            value (id) {
              return client.post(`/EdgeDevices/${id}/deployment`).fetch()
            },
          },
          getModules: {
            enumerable: true,
            value (deviceId) {
              return client.get(`/EdgeDevices/${deviceId}/modules`).fetch()
            },
          },
          refreshModule: {
            enumerable: true,
            value (deviceId, moduleId) {
              return client.post(`/EdgeDevices/${deviceId}/modules/${moduleId}/restart`).fetch()
            },
          },
          getConfigs: {
            enumerable: true,
            value (deviceId, type) {
              return client.get(`/EdgeDeviceConfigs/${deviceId}/${type}`).fetch()
            },
          },
          createConfigs: {
            enumerable: true,
            value ({ deviceId, ...params }) {
              return client.post(`/EdgeDeviceConfigs/${deviceId}`, { ...params }).fetch()
            },
          },
          updateConfigs: {
            enumerable: true,
            value ({ deviceId, ...params }) {
              return client.put(`/EdgeDeviceConfigs/${deviceId}`, { ...params }).fetch()
            },
          },
          deleteConfigs: {
            enumerable: true,
            value (deviceId, type, configId) {
              return client.delete(`/EdgeDeviceConfigs/${deviceId}/${type}/${configId}`).fetch()
            },
          },
          getFunctions: {
            enumerable: true,
            value (deviceId) {
              return client.get(`/EdgeDeviceFunctions/${deviceId}`).fetch()
            },
          },
          getFunction: {
            enumerable: true,
            value (deviceId, name) {
              return client.get(`/EdgeDeviceFunctions/${deviceId}/${name}`).fetch()
            },
          },
          createFunctions: {
            enumerable: true,
            value ({ deviceId, ...params }) {
              return client.post(`/EdgeDeviceFunctions/${deviceId}`, { ...params }).fetch(true)
            },
          },
          updateFunctions: {
            enumerable: true,
            value ({ deviceId, ...params }) {
              return client.put(`/EdgeDeviceFunctions/${deviceId}`, { ...params }).fetch(true)
            },
          },
          deleteFunction: {
            enumerable: true,
            value (deviceId, name) {
              return client.delete(`/EdgeDeviceFunctions/${deviceId}/${name}`).fetch()
            },
          },
          getLogs: {
            enumerable: true,
            value ({ ...params }) {
              return client.post('/EdgeLogs', { ...params }).fetch()
            },
          },
        }),
      },
    })
  }
}

import { useCallback } from 'react'
import { auth } from '@/auth'

const useAuthComponents = () => {
  const isPersonal = auth.checkAccessPersonal('SectionComponents')
  const isOrganizational = auth.checkAccessOrganization('SectionComponents')
  const isRead = auth.checkAccessRead('SectionComponents')
  const isPersonalStrictly = isPersonal && !isOrganizational
  const isNotManagable = !isPersonal && !isOrganizational

  const isReadOnly = isRead && isNotManagable

  // to check if current user has acces to current component
  const checkAvailability = useCallback((isPersonalComponent) => {
    if (isOrganizational) {
      return true
    }
    if (isReadOnly) {
      return false
    }
    if (isPersonalStrictly && isPersonalComponent) {
      return true
    }

    return false
  }, [ isPersonalStrictly, isReadOnly, isOrganizational ])

  return {
    isRead, isReadOnly, isPersonal, isPersonalStrictly, isOrganizational, checkAvailability,
  }
}

export default useAuthComponents

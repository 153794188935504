import {
  useRef, useState,
} from 'react'
import cx from 'classnames'
import { Icon as LegacyIcon, Dialog, FormSelectDateTimeRange } from '@wiz/components'
import { BOARD_VIEW_ENUM, SEVERITY_ENUM } from '@/utils/consts'
import Icon from '@/shared/icon'
import FormatDateTime from '@/containers/FormatDateTime'
import { useIntl } from '@wiz/intl'
import { AllSeverities } from '@/pages/assets/svg/AllSeverities'
import useAppContext from '@/hooks/useAppContext'
import classes from '../index.module.css'

const MobileFilters = ({ onClose }) => {
  const [ isTouched, setIsTouched ] = useState(false)
  const [ userValue, setUserValue ] = useState([ null, null ])

  const refRightBar = useRef()

  const intl = useIntl()

  const {
    operateFilters,
    onOperateFiltersChange,
    dashboard,
    formData,
    handleSaveFormData,
  } = useAppContext()

  const defaultValues = [ dashboard?.defaultFrom, dashboard?.defaultTo ]

  return (
    <Dialog
      ref={refRightBar}
      classNameBody="p-0 bg-light-alt"
      container="#context-container"
      strategy="absolute"
      backdrop
      // title={intl.t(section.title)}
      dataTestid="MobileFiltersDialog"
      onClose={onClose}
      orient="right"
      stickToTop
      headerActions={(
        <div className="d-flex align-items-center w-100">
          <Icon name="faFilter" size="lg" className="me-2" />
          <span className="fs-5">{intl.t('t/form.actions.filters')}</span>
        </div>
)}
    >
      <div className={cx(
        'd-flex flex-column',
      )}
      >
        {dashboard?.type === 'Grafana' ? (
          <div className={cx(
            'd-flex flex-column pb-2',
            classes.intervalContainer,
            classes.mobileFiltersBg,
          )}
          >
            <span className={cx('fill-flex text-start text-truncate fs-6 mb-1', classes.semibold)}>
              {intl.t('t/enum.inputType.sensorInterval')}
            </span>
            <FormSelectDateTimeRange
              value={(
              formData.dateFrom && formData.dateTo ?
                [ formData.dateFrom, formData.dateTo ] :
                [ null, null ]
            )}
              DateTimeComponent={FormatDateTime}
              size="sm"
              clearable
              isRenderWithDefault
              defaultValues={defaultValues}
              userValue={userValue}
              setUserValue={setUserValue}
              isTouched={isTouched}
              setIsTouched={setIsTouched}
              onChange={([ dateFrom, dateTo ]) => {
                handleSaveFormData({ dateFrom, dateTo })
              }}
            />
          </div>
        ) : null}
        <div className={cx(
          'd-flex flex-column',
        )}
        >
          <div
            className={cx(
              'btn btn-secondary-alt py-25 d-flex justify-content-start border-y',
              classes.menuItem,
              classes.menuItemMobile,
              classes.mobileFiltersBg,
              classes.br0,
            )}
          >
            <span className={cx('fill-flex text-start text-truncate fs-6', classes.semibold)}>
              Favorites
            </span>
          </div>
          <div
            className={cx(
              'btn btn-secondary-alt py-25 d-flex justify-content-start',
              classes.menuItem,
              classes.menuItemMobile,
              classes.br0,
              { [classes.menuItemActive]: operateFilters.isShowFavorites },
            )}
            onClick={() => onOperateFiltersChange({ isShowFavorites: !operateFilters.isShowFavorites })}
            aria-hidden
          >
            <Icon
              name="faStar"
              width={14}
              height={14}
              className="me-2"
            />
            <span className={cx('fill-flex text-start text-truncate fs-6')}>
              Favorites only
            </span>
            {operateFilters.isShowFavorites ? <Icon name="faCircleCheck" className="ms-auto" /> : null}
          </div>
        </div>
        <div className={cx(
          'd-flex flex-column',
        )}
        >
          <div
            className={cx(
              'btn btn-secondary-alt py-25 d-flex justify-content-start border-y',
              classes.menuItem,
              classes.menuItemMobile,
              classes.mobileFiltersBg,
              classes.br0,
            )}
          >
            <span className={cx('fill-flex text-start text-truncate fs-6', classes.semibold)}>
              Assets view
            </span>
          </div>
          <div
            className={cx(
              'btn btn-secondary-alt py-25 d-flex justify-content-start',
              classes.menuItem,
              classes.menuItemMobile,
              classes.br0,
              { [classes.menuItemActive]: !operateFilters.isShowChildren },
            )}
            onClick={() => onOperateFiltersChange({ isShowChildren: false })}
            aria-hidden
          >
            <LegacyIcon className="me-2" name="wiz--other--blocks" width={14} height={14} size="2X" />
            <span className={cx('fill-flex text-start text-truncate fs-6')}>
              Directly assets only
            </span>
            {!operateFilters.isShowChildren ? <Icon name="faCircleCheck" className="ms-auto" /> : null}
          </div>
          <div
            className={cx(
              'btn btn-secondary-alt py-25 d-flex justify-content-start',
              classes.menuItem,
              classes.menuItemMobile,
              classes.br0,
              { [classes.menuItemActive]: operateFilters.isShowChildren },
            )}
            onClick={() => onOperateFiltersChange({ isShowChildren: true })}
            aria-hidden
          >
            <LegacyIcon className="me-2" name="wiz--other--blocks-children" width={14} height={14} size="2X" />
            <span className={cx('fill-flex text-start text-truncate fs-6')}>
              All sub-assets
            </span>
            {operateFilters.isShowChildren ? <Icon name="faCircleCheck" className="ms-auto" /> : null}
          </div>
        </div>
        <div className={cx(
          'd-flex flex-column',
        )}
        >
          <div
            className={cx(
              'btn btn-secondary-alt py-25 d-flex justify-content-start border-y',
              classes.menuItem,
              classes.menuItemMobile,
              classes.mobileFiltersBg,
              classes.br0,
            )}
          >
            <span className={cx('fill-flex text-start text-truncate fs-6', classes.semibold)}>
              Boards view
            </span>
          </div>
          <div
            className={cx(
              'btn btn-secondary-alt py-25 d-flex justify-content-start',
              classes.menuItem,
              classes.menuItemMobile,
              classes.br0,
              { [classes.menuItemActive]: (!operateFilters.boardView || operateFilters.boardView === BOARD_VIEW_ENUM.GLOBAL) },
            )}
            onClick={() => onOperateFiltersChange({ boardView: BOARD_VIEW_ENUM.GLOBAL })}
            aria-hidden
          >
            <Icon
              name="faGlobe"
              width={14}
              height={14}
              className="me-2"
            />
            <span className={cx('fill-flex text-start text-truncate fs-6')}>
              Global
            </span>
            {!operateFilters.boardView || operateFilters.boardView === BOARD_VIEW_ENUM.GLOBAL ?
              <Icon name="faCircleCheck" className="ms-auto" /> :
              null}
          </div>
          <div
            className={cx(
              'btn btn-secondary-alt py-25 d-flex justify-content-start',
              classes.menuItem,
              classes.menuItemMobile,
              classes.br0,
              { [classes.menuItemActive]: operateFilters.boardView === BOARD_VIEW_ENUM.ORGANIZATIONAL },
            )}
            onClick={() => onOperateFiltersChange({ boardView: BOARD_VIEW_ENUM.ORGANIZATIONAL })}
            aria-hidden
          >
            <Icon
              name="faPeopleGroup"
              width={14}
              height={14}
              className="me-2"
            />
            <span className={cx('fill-flex text-start text-truncate fs-6')}>
              Organizational
            </span>
            {operateFilters.boardView === BOARD_VIEW_ENUM.ORGANIZATIONAL ?
              <Icon name="faCircleCheck" className="ms-auto" /> : null}
          </div>
          <div
            className={cx(
              'btn btn-secondary-alt py-25 d-flex justify-content-start',
              classes.menuItem,
              classes.menuItemMobile,
              classes.br0,
              { [classes.menuItemActive]: operateFilters.boardView === BOARD_VIEW_ENUM.PERSONAL },
            )}
            onClick={() => onOperateFiltersChange({ boardView: BOARD_VIEW_ENUM.PERSONAL })}
            aria-hidden
          >
            <Icon
              name="faUser"
              width={14}
              height={14}
              className="me-2"
            />
            <span className={cx('fill-flex text-start text-truncate fs-6')}>
              Personal
            </span>
            {operateFilters.boardView === BOARD_VIEW_ENUM.PERSONAL ?
              <Icon name="faCircleCheck" className="ms-auto" /> : null}
          </div>
        </div>
        <div className={cx(
          'd-flex flex-column',
        )}
        >
          <div
            className={cx(
              'btn btn-secondary-alt py-25 d-flex justify-content-start border-y',
              classes.menuItem,
              classes.menuItemMobile,
              classes.mobileFiltersBg,
              classes.br0,
            )}
          >
            <span className={cx('fill-flex text-start text-truncate fs-6', classes.semibold)}>
              Severity
            </span>
          </div>
          <div
            className={cx(
              'btn btn-secondary-alt py-25 d-flex justify-content-start',
              classes.menuItem,
              classes.menuItemMobile,
              classes.br0,
              { [classes.menuItemActive]: operateFilters.severity === SEVERITY_ENUM.CRITICAL },
            )}
            onClick={() => onOperateFiltersChange({ severity: SEVERITY_ENUM.CRITICAL })}
            aria-hidden
          >
            <Icon
              name="faTriangleExclamation"
              size="md"
              width={14}
              color="#D71818"
              className="me-2"
            />
            <span className={cx('fill-flex text-start text-truncate fs-6')}>
              Critical / Error
            </span>
            {operateFilters.severity === SEVERITY_ENUM.CRITICAL ?
              <Icon name="faCircleCheck" className="ms-auto" /> : null}
          </div>
          <div
            className={cx(
              'btn btn-secondary-alt py-25 d-flex justify-content-start',
              classes.menuItem,
              classes.menuItemMobile,
              classes.br0,
              { [classes.menuItemActive]: operateFilters.severity === SEVERITY_ENUM.WARNING },
            )}
            onClick={() => onOperateFiltersChange({ severity: SEVERITY_ENUM.WARNING })}
            aria-hidden
          >
            <Icon
              name="faDiamondExclamation"
              size="md"
              width={14}
              color="#fddd60"
              className="me-2"
            />
            <span className={cx('fill-flex text-start text-truncate fs-6')}>
              Warning
            </span>
            {operateFilters.severity === SEVERITY_ENUM.WARNING ?
              <Icon name="faCircleCheck" className="ms-auto" /> : null}
          </div>
          <div
            className={cx(
              'btn btn-secondary-alt py-25 d-flex justify-content-start',
              classes.menuItem,
              classes.menuItemMobile,
              classes.br0,
              { [classes.menuItemActive]: operateFilters.severity === SEVERITY_ENUM.SUCCESS },
            )}
            onClick={() => onOperateFiltersChange({ severity: SEVERITY_ENUM.SUCCESS })}
            aria-hidden
          >
            <Icon
              name="faCircleCheck"
              size="md"
              width={14}
              color="#7cffb2"
              className="me-2"
            />
            <span className={cx('fill-flex text-start text-truncate fs-6')}>
              Success / Positive
            </span>
            {operateFilters.severity === SEVERITY_ENUM.SUCCESS ?
              <Icon name="faCircleCheck" className="ms-auto" /> : null}
          </div>
          <div
            className={cx(
              'btn btn-secondary-alt py-25 d-flex justify-content-start',
              classes.menuItem,
              classes.menuItemMobile,
              classes.br0,
              { [classes.menuItemActive]: (!operateFilters.severity || operateFilters.severity === SEVERITY_ENUM.ALL) },
            )}
            onClick={() => onOperateFiltersChange({ severity: SEVERITY_ENUM.ALL })}
            aria-hidden
          >
            <AllSeverities width={14} height={14} className="me-2" />
            <span className={cx('fill-flex text-start text-truncate fs-6')}>
              Show all
            </span>
            {(!operateFilters.severity || operateFilters.severity === SEVERITY_ENUM.ALL) ?
              <Icon name="faCircleCheck" className="ms-auto" /> : null}
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default MobileFilters

import { useMemo } from 'react'
import FormDialog from '@/components/Form/FormDialog'
import Section from './Section'
import Removed from '../../Removed'

export default function InstalledPackages ({
  type,
  packages,
  dialog,
  onClose,
  onSubmit,
}) {
  // const handleSubmit = async (data) => {
  //   console.log('handleSubmit')
  // }

  const defaultValues = useMemo(() => ({
    installedPackages: { packages: JSON.stringify(packages, null, 2) },
  }), [ packages ])

  if (!packages) {
    return <Removed />
  }

  return (
    <FormDialog
      // onSubmit={handleSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="installedPackages"
      dialog={dialog}
    >
      <Section
        scope="installedPackages"
      />
    </FormDialog>
  )
}

import {
  useEffect, useMemo, useState,
} from 'react'
import { Duration, DateTime } from 'luxon'

const DefaultParts = [
  'days',
  'hours',
  'minutes',
  'seconds',
  'milliseconds',
]
const DefaultPartsMap = {
  years: 'y',
  months: 'M',
  weeks: 'w',
  days: 'd',
  hours: 'h',
  minutes: 'm',
  seconds: 's',
  milliseconds: 'ms',
}

function valueToObject (value, parts, iso) {
  let val = value || 0
  if (Number.isInteger(val)) {
    const range = DateTime.local().toMillis() - val
    val = Duration.fromMillis(range)
      .shiftTo(...parts)
      .toObject()
  } else if (typeof val === 'string' && iso) {
    val = Duration.fromISO(val)
      .shiftTo(parts.join(','))
      .toObject()
  } else {
    val = {}
  }
  return val
}

const CellTime = ({
  value, signed = false, iso = false, parts, prefix = '',
}) => {
  const [ pingTimer, setPingTimer ] = useState(value)

  useEffect(() => {
    setPingTimer(value)
    const interval = setInterval(() => {
      setPingTimer(pingTimer + 1000)
    }, 1000)

    return () => clearInterval(interval)
  }, [ value, pingTimer ])

  const duration = useMemo(() => {
    const obj = valueToObject(pingTimer, parts?.split(',') ?? DefaultParts, iso)

    return obj
  }, [ value, pingTimer, signed, iso, parts ])

  const resultString = useMemo(() => Object.entries(duration).reduce((acc, [ key, val ]) => {
    if (!val) {
      return acc
    }
    acc += `${Math.round(val)}${DefaultPartsMap[key]} `
    return acc
  }, ''), [ duration ])

  return <div>{`${prefix ? `${prefix} ` : ''}${resultString || value}` || '--'}</div>
}

export default CellTime

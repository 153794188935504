import { useCallback } from 'react'
import { useRouter } from '@/router'
import { dbProvider } from '@wiz/store'
import cx from 'classnames'
import { Icon, useOnline, withObservables } from '@wiz/components'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import { appEnv } from '@/config'

const Logo = ({
  className,
  env,
}) => {
  const online = useOnline()
  const envLogo = env?.EnvironmentLogo
  const router = useRouter()
  const { handleTwinChange, isEditingDashboard, setIsEditingDashboard } = useGlobalExecute()

  const handleClick = useCallback(async () => {
    if (isEditingDashboard) {
      await window.wizConfirm({ message: 't/components.leaveEdit' })
      setIsEditingDashboard(false)
    }
    handleTwinChange(null, true)
    router.push({})
  }, [ router, handleTwinChange, isEditingDashboard, setIsEditingDashboard ])

  return (
    <div
      className={cx(className, 'pointer')}
      onClick={handleClick}
      style={!online ? {
        filter: 'grayscale(1)',
      } : undefined}
      aria-hidden
    >
      {envLogo ? <img src={envLogo} alt="logo" width={40} height={40} style={{ objectFit: 'contain' }} /> : (
        <Icon
          name={appEnv.WIZ_CLIENT_LOGO}
          width={40}
          height={40}
        />
      )}
    </div>
  )
}

const enhanceSettings = withObservables([], () => ({
  env: dbProvider.observeGlobalSettings([ 'EnvironmentLogo' ]),
}))

export default enhanceSettings(Logo)

import {
  useState, useEffect, useRef, useContext, createContext, useMemo,
} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import queryString from 'query-string'
import { withObservables, useMobile } from '@wiz/components'
import { dbProvider } from '@wiz/store'
import { useIntl } from '@wiz/intl'
import TopMenu from '@/containers/TopMenu/enhanceProps'
import ButtonSettings from './ButtonSettings'
import ButtonUser from './ButtonUser'
import ButtonNotify from './ButtonNotify'
import Logo from './Logo'
import classes from './index.css'
import ButtonApi from './ButtonApi'
import ButtonDocs from './ButtonDocs'
import ButtonTheme from './ButtonTheme'

const UserContext = createContext()

const enhanceSettings = withObservables([], () => ({
  headerPin: dbProvider.observeSettings('headerPin'),
  featureFlags: dbProvider.observeFlags([ 'UseSns' ]),
}))

const Header = ({
  headerPin,
  featureFlags,
}) => {
  const refRoot = useRef()
  const isMobile = useMobile()
  const intl = useIntl()
  const [ user, setUser ] = useState({ avatar: '' })
  const [ collapse, setCollapse ] = useState(!headerPin && !isMobile)

  const query = queryString.parse(window.location.search)

  useEffect(() => {
    const rootComponent = refRoot.current
    let expandTimer
    let collapseTimer

    const clearTimers = () => {
      window.clearTimeout(expandTimer)
      window.clearTimeout(collapseTimer)
      expandTimer = 0
      collapseTimer = 0
    }
    const handleCollapse = () => {
      clearTimers()
      setCollapse(true)
    }
    const headerExpand = () => {
      clearTimers()
      setCollapse(false)
    }
    const handleMousemove = (event) => {
      if (collapse && event.clientY < 20 && !expandTimer) {
        expandTimer = window.setTimeout(headerExpand, 500)
      } else if (collapse && event.clientY > 20 && expandTimer) {
        clearTimers()
      } else if (!collapse && event.clientY > 60 && !collapseTimer) {
        collapseTimer = window.setTimeout(handleCollapse, 4000)
      }
    }
    const handleMouseenter = () => headerExpand()
    const handleMouseleave = () => {
      if (!collapse && !collapseTimer) {
        collapseTimer = window.setTimeout(handleCollapse, 4000)
      }
    }

    if (headerPin) {
      setCollapse(false)
    } else if (!isMobile) {
      collapseTimer = window.setTimeout(handleCollapse, 4000)
      document.addEventListener('mousemove', handleMousemove)
      document.addEventListener('mouseleave', clearTimers)
      rootComponent.addEventListener('mouseenter', handleMouseenter)
      rootComponent.addEventListener('mouseleave', handleMouseleave)
    }

    return () => {
      clearTimers()
      document.removeEventListener('mousemove', handleMousemove)
      document.removeEventListener('mouseleave', clearTimers)
      rootComponent?.removeEventListener('mouseenter', handleMouseenter)
      rootComponent?.removeEventListener('mouseleave', handleMouseleave)
    }
  }, [ headerPin, collapse, isMobile ])

  const userContext = useMemo(() => ({
    user,
    setUser,
  }), [ user, setUser ])

  if (query?.header === 'no') {
    return null
  }
  return (
    <UserContext.Provider value={userContext}>
      <div
        ref={refRoot}
        className={classnames('px-2', classes.root, {
          [classes.rootCollapse]: collapse && !isMobile,
          [classes.rootMobile]: isMobile,
        })}
      >
        {isMobile ? null : (
          <nav className={classnames('w-100', classes.root)}>
            <Logo />
            <TopMenu className="d-flex align-items-center mx-1" />
            <div className="d-flex flex-column ms-auto">
              <div className={classnames('d-flex align-items-center mx-2', classes.rightBarIcons)}>
                {/* <ButtonTheme /> */}
                <ButtonDocs />
                <ButtonApi />
                <ButtonNotify />
                <ButtonSettings featureFlags={featureFlags} />
                <ButtonUser user={user} />
              </div>
            </div>
          </nav>
        )}
      </div>
    </UserContext.Provider>
  )
}

Header.propTypes = {
  headerPin: PropTypes.bool,
}

Header.defaultProps = {
  headerPin: false,
}

export default enhanceSettings(
  Header,
)

import { useMemo } from 'react'
import FormDialog from '@/components/Form/FormDialog'
import Section from './Section'
import Removed from '../../Removed'

export default function CustomPackages ({
  type,
  packages,
  dialog,
  onClose,
  onSubmit,
}) {
  // const handleSubmit = async (data) => {}

  const defaultValues = useMemo(() => ({
    customPackages: { packages: JSON.stringify(packages, null, 2) },
  }), [ packages ])

  if (!packages) {
    return <Removed />
  }

  return (
    <FormDialog
      // onSubmit={handleSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="customPackages"
      dialog={dialog}
    >
      <Section
        scope="customPackages"
      />
    </FormDialog>
  )
}

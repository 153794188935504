import {
  useMemo, useEffect, useState, useCallback,
} from 'react'
import { useIntl } from '@wiz/intl'
import { wizataApi } from '@/api'
import { useAuth } from '@/auth'
import { useQuery } from '@tanstack/react-query'
import { TableWidget, Icon as LegacyIcon } from '@wiz/components'
import FormInstalledPackages from '@/components/Forms/EngineManagement/InstalledPackages'
import FormCustomPackages from '@/components/Forms/EngineManagement/CustomPackages'
import events from '@/utils/events'
import Icon from '@/shared/icon'
import cx from 'classnames'
import { RunnerStatusColorsScheme, RunnerStatus } from '@/utils/consts'
import CellTime from './CellTime'
import classes from './index.module.css'

const STATUS_STYLES = {
  [RunnerStatus.running]: {
    backgroundColor: RunnerStatusColorsScheme[RunnerStatus.running],
    borderColor: RunnerStatusColorsScheme[RunnerStatus.running],
  },
  [RunnerStatus.listening]: {
    backgroundColor: RunnerStatusColorsScheme[RunnerStatus.running],
    borderColor: RunnerStatusColorsScheme[RunnerStatus.running],
  },
  [RunnerStatus.terminating]: {
    backgroundColor: RunnerStatusColorsScheme[RunnerStatus.terminating],
    borderColor: RunnerStatusColorsScheme[RunnerStatus.terminating],
    color: '#fff',
  },
}

const INFO_RESTART_DELAY = 15000
const INFO_AFTER_PING_DELAY = 3000
const PING_RESTART_DELAY = 60000

const RunnersWidget = ({
  entityType,
  title,
  titleIcon = 'wiz--menu--engine-management',
}) => {
  const intl = useIntl()
  const [ editItem, setEditItem ] = useState({ component: '' })
  const [ pingTimer, setPingTimer ] = useState(null)

  const info = useQuery({
    queryKey: [ 'pipelineManagementInfo', entityType ],
    queryFn: () => wizataApi.engine.info(entityType),
    refetchOnWindowFocus: false,
    enabled: false,
    retry: false,
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/engineManagement.errors.info',
        message: err.message,
        duration: 5000,
      })
    },
  })
  const initPing = useQuery({
    queryKey: [ 'initPing', entityType ],
    queryFn: () => wizataApi.engine.ping(entityType),
    refetchOnWindowFocus: false,
    enabled: false,
    retry: false,
    // onError: (err) => {
    //   events.emit('app:notify', {
    //     type: 'error',
    //     title: 't/engineManagement.errors.ping',
    //     message: err.message,
    //     duration: 5000,
    //   })
    // },
  })
  const ping = useQuery({
    queryKey: [ 'pipelineManagementPing', entityType ],
    queryFn: () => wizataApi.engine.ping(entityType),
    refetchOnWindowFocus: false,
    enabled: false,
    retry: false,
    // onError: (err) => {
    //   events.emit('app:notify', {
    //     type: 'error',
    //     title: 't/engineManagement.antiSpam',
    //     message: err.message,
    //     duration: 5000,
    //   })
    // },
    onSuccess: (data) => {
      if (typeof data === 'string') {
        events.emit('app:notify', {
          type: 'success',
          title: 'ping!',
          message: data,
        })
      }
    },
  })
  const restart = useQuery({
    queryKey: [ 'pipelineManagementRestart', entityType ],
    queryFn: () => wizataApi.engine.restart(entityType),
    refetchOnWindowFocus: false,
    enabled: false,
    retry: false,
    onSuccess: (data) => {
      if (typeof data === 'string') {
        events.emit('app:notify', {
          type: 'info',
          title: 'restart!',
          message: data,
        })
      }
    },
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/engineManagement.errors.restart',
        message: err.message,
        duration: 5000,
      })
    },
  })
  const forceRestart = useQuery({
    queryKey: [ 'pipelineManagementForceRestart', entityType ],
    queryFn: () => wizataApi.engine.forceRestart(entityType),
    refetchOnWindowFocus: false,
    enabled: false,
    retry: false,
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/engineManagement.errors.forceRestart',
        message: err.message,
        duration: 5000,
      })
    },
  })
  const pause = useQuery({
    queryKey: [ 'pipelineManagementPause', entityType ],
    queryFn: () => wizataApi.engine.pause(entityType),
    refetchOnWindowFocus: false,
    enabled: false,
    retry: false,
    onSuccess: (data) => {
      if (typeof data === 'string') {
        events.emit('app:notify', {
          type: 'info',
          title: 'pause!',
          message: data,
        })
      }
    },
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/engineManagement.errors.pause',
        message: err.message,
        duration: 5000,
      })
    },
  })

  useEffect(() => {
    if (info?.data?.lastPing) {
      setPingTimer(info?.data?.lastPing)
    }
  }, [ info ])

  const infoAfterPing = useCallback(() => setTimeout(() => info.refetch(), INFO_AFTER_PING_DELAY), [ info ])

  const handlePing = useCallback(() => {
    ping.refetch()
    infoAfterPing()
  }, [ infoAfterPing, ping ])

  const handleForceRestart = useCallback(async () => {
    await window.wizConfirm({ message: 't/engineManagement.confirmForceRestart' })
    forceRestart.refetch()
  }, [ forceRestart ])

  const renderPingTime = useMemo(() => (
    <div className={cx(classes.lastPing, 'me-2 align-self-center')}>
      {pingTimer ? (
        <CellTime
          prefix="Ping:"
          value={pingTimer}
          parts="seconds"
        />
      ) : null}

    </div>
  ), [ pingTimer ])

  const columns = useMemo(() => [
    {
      Header: intl.t('form.fields.runner'),
      accessor: 'id',
      customHeaderStyles: {
        backgroundColor: '#031E37',
      },
      disableSortBy: true,
      minWidth: 70,
      width: 70,
      Cell: ({ cell, row }) => {
        const splitArr = cell.value.split('-') || []
        return (cell.value ? (
          <div className="text-truncate min-w-0 p-2">
            {splitArr[splitArr.length - 1]}
          </div>
        ) : '-')
      },
    },
    {
      Header: intl.t('form.fields.status'),
      accessor: 'status',
      customHeaderStyles: {
        backgroundColor: '#031E37',
      },
      className: 'justify-content-center',
      disableSortBy: true,
      minWidth: 100,
      width: 100,
      Cell: ({ cell, row }) => (cell.value ? (
        <div
          className={cx(classes.runnerStatus, 'text-truncate')}
          style={
              {
                backgroundColor: RunnerStatusColorsScheme[RunnerStatus.starting],
                borderColor: RunnerStatusColorsScheme[RunnerStatus.starting],
                color: '#01101d',
                ...STATUS_STYLES[cell.value],
              }
            }
        >
          {cell.value === RunnerStatus.listening ? RunnerStatus.running : RunnerStatus[cell.value]}
        </div>
      ) : '-'),
    },
    {
      Header: intl.t('form.fields.started'),
      accessor: 'startDate',
      customHeaderStyles: {
        backgroundColor: '#031E37',
      },
      minWidth: 100,
      width: 100,
      Cell: ({ cell, row }) => (
        <CellTime
          value={cell.value}
          parts="hours,minutes"
        />
      ),
    },
    {
      Header: intl.t('form.fields.refreshed'),
      accessor: 'logDate',
      customHeaderStyles: {
        backgroundColor: '#031E37',
      },
      minWidth: 100,
      width: 100,
      Cell: ({ cell, row }) => (
        <CellTime
          value={cell.value}
          parts="seconds"
        />
      ),
    },
    {
      Header: intl.t('form.fields.resources'),
      accessor: 'rewq',
      customHeaderStyles: {
        backgroundColor: '#031E37',
      },
      className: 'justify-content-center',
      disableResizing: true,
      minWidth: 80,
      width: 80,
      maxWidth: 80,
      Cell: ({ cell, row }) => {
        const { stats } = row.original
        return (
          <div
            className="position-relative min-w-0 d-flex"
            aria-hidden
          >
            <button
              className={cx(classes.btnInfo, 'btn')}
              type="button"
            >
              <Icon name="faCircleInfo" />
            </button>
            <div className={classes.tooltiptext}>
              <div className="d-flex justify-content-between">
                <span>CPU</span>
                <span>{`${Number(stats.cpu_usage).toFixed(2)} %`}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span>Total RAM</span>
                <span>{`${Number(stats.total_ram).toFixed(2)} Gb`}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span>Used RAM</span>
                <span>{`${Number(stats.used_ram).toFixed(2)} Gb`}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span>Available RAM</span>
                <span>{`${Number(stats.available_ram).toFixed(2)} Gb`}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span>Usage RAM</span>
                <span>{`${Number(stats.ram_usage_percent).toFixed(2)} %`}</span>
              </div>
            </div>
          </div>
        )
      },
    },
  ], [ intl ])

  const actionButtons = useMemo(() => (
    <div className="d-flex">
      {renderPingTime}
      <button
        type="button"
        className={cx(classes.btnAction, 'btn btn-fill-secondary-alt me-2')}
        onClick={() => restart.refetch()}
      >
        <Icon name="faArrowRotateRight" className="me-2" size="md" />
        {intl.t('form.actions.restart')}
      </button>
      <button
        type="button"
        className={cx(classes.btnAction, 'btn btn-fill-secondary-alt me-2')}
        onClick={handlePing}
      >
        <Icon name="faArrowsRetweet" className="me-2" size="md" />
        {intl.t('form.actions.ping')}
      </button>
      <button
        type="button"
        className={cx(classes.btnAction, 'btn btn-fill-secondary-alt me-2')}
        onClick={() => pause.refetch()}
      >
        <Icon name="faPause" className="me-2" size="md" />
        {intl.t('form.actions.pause')}
      </button>
      <button
        type="button"
        className={cx(classes.btnForce, 'btn btn-fill-secondary-alt')}
        onClick={handleForceRestart}
      >
        <Icon name="faRotateExclamation" className="me-2" size="md" />
        {intl.t('form.actions.forceRestart')}
      </button>
    </div>
  ), [ intl, handleForceRestart, handlePing, pause, restart, renderPingTime ])

  const initPingTimer = async () => {
    initPing.refetch()
    infoAfterPing()
  }

  useEffect(() => {
    initPingTimer()
  }, [])

  useEffect(() => {
    const interval = setInterval(initPingTimer, PING_RESTART_DELAY)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const interval = setInterval(info.refetch, INFO_RESTART_DELAY)

    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <div>
        <div className={cx(classes.topbar, 'd-flex justify-content-between py-2')}>
          <div style={{ fontWeight: 600 }}>
            <LegacyIcon className="me-2" name={titleIcon} width={20} height={20} />
            {intl.t(title)}
          </div>
          <div>
            <button
              type="button"
              className="btn btn-fill-secondary-alt me-2"
              onClick={() => setEditItem({ component: 'installedPackages' })}
            >
              <Icon name="faEye" className="me-2" size="md" />
              {intl.t('form.actions.installedPackages')}
            </button>
            <button
              type="button"
              className="btn btn-fill-secondary-alt"
              onClick={() => setEditItem({ component: 'customPackages' })}
            >
              <Icon name="faPenToSquare" className="me-2" size="md" />
              {intl.t('form.actions.customPackages')}
            </button>
          </div>

        </div>
        <div className="d-flex" />
        <TableWidget
          withCount
          title="Runners"
          tableColumns={columns}
          tablePlaceholder=" "
          tableData={info?.data?.info}
          actionBar={actionButtons}
          defaultHeight={180}
          itemSize={45}
          loading={info.isLoading || initPing.isLoading}
        />
      </div>

      {editItem?.component === 'installedPackages' ? (
        <FormInstalledPackages
          onClose={() => setEditItem(null)}
          type={entityType}
          packages={info?.data?.packages}
          dialog={{
            title: intl.t('engineManagement.installedPackages.title'),
            dataTestid: 'replaceInstalledPackagesDialog',
          }}
        />
      ) : null}

      {editItem?.component === 'customPackages' ? (
        <FormCustomPackages
          onClose={() => setEditItem(null)}
          type={entityType}
          packages={info?.data?.packages}
          dialog={{
            title: intl.t('engineManagement.customPackages.title'),
            dataTestid: 'replaceCustomPackagesDialog',
          }}
        />
      ) : null}
    </>
  )
}

export default RunnersWidget

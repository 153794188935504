import { map } from 'rxjs/operators'
import { of } from 'rxjs'
import { withObservables } from '@wiz/components'
import Link from '@/components/Link'
import { dbProvider, Q } from '@wiz/store'

const enhanceProps = withObservables([ 'twinId' ], ({ twinId }) => {
  if (!twinId) {
    return {
      twin: of(undefined),
    }
  }

  const query = dbProvider.database.collections.get('twins').query(Q.where('id', twinId))

  return {
    twin: query.observeWithColumns([ 'updated_at' ]).pipe(map(items => items[0])),
  }
})

function CellTwin ({
  twin,
}) {
  if (!twin) {
    return <>-</>
  }
  return (
    <div className="min-w-0 d-flex">
      <Link
        className="min-w-0 text-truncate"
        title={twin.name}
        name="twin-items-list"
        query={{ tree: twin?.id }}
      >
        {twin.name}
      </Link>
    </div>
  )
}

export default enhanceProps(CellTwin)

import { useEffect, useMemo, useCallback } from 'react'
import cx from 'classnames'
import Icon from '@/shared/icon'
import { getStatus } from '@/utils/insights'
import { SEVERITY_STATUS_MAP, SEVERITY_ENUM } from '@/utils/consts'
import { testJSON } from '@/utils/json'
import useAppContext from '@/hooks/useAppContext'
import s from '../index.module.css'

const Insight = ({
  name, displayPrecision, id, hardwareId, sensorValues, condition, cardId, className, symbol,
}) => {
  const { onSeveritiesChange, severities } = useAppContext()

  const roundedValue = useMemo(() => {
    const value = sensorValues?.data?.[hardwareId]?.lastValue
    if (value === undefined || value === null) {
      return null
    }
    if (displayPrecision) {
      const precision = 10 ** (displayPrecision || 0)
      return Math.round(value * precision) / precision
    }
    return Math.round(value)
  }, [ displayPrecision, sensorValues, hardwareId ])

  const currentStatus = useMemo(() => {
    if (!testJSON(condition)) {
      return null
    }
    const status = getStatus(roundedValue, JSON.parse(condition)?.parsed)
    if (status) {
      const foundStatus = SEVERITY_STATUS_MAP.find(st => Number(st.id) === Number(status.severity))
      if (foundStatus) {
        if (foundStatus?.id === 3) {
          return null
        }
        return foundStatus
      }
    }
    return null
  }, [ roundedValue, condition ])

  const updateCardsStatus = useCallback(() => {
    if (currentStatus) {
      onSeveritiesChange(currentStatus.code, cardId, id)
    } else {
      const hasStatus = !!severities[cardId]?.[id]
      if (hasStatus) {
        onSeveritiesChange(SEVERITY_ENUM.INFO, cardId, id)
      }
    }
  }, [ cardId, onSeveritiesChange, id, currentStatus, severities ])

  useEffect(() => {
    updateCardsStatus()
  }, [ currentStatus ])

  const sensorValuesLoading = sensorValues?.isLoading || sensorValues?.isFetching || sensorValues?.reFetching

  return (
    <div
      className={cx('d-flex align-items-center', s.insight, className, {
        'fw-bold': !!currentStatus,
        [s[currentStatus?.code]]: !!currentStatus,
        [s.info]: !currentStatus,
      })}
    >
      <div className={cx('flex-fill text-truncate pe-3')}>
        <div className="fs-7">
          {name}
        </div>
        <div className={cx('fs-8 text-truncate')}>
          {hardwareId}
        </div>
      </div>
      <div className={cx('ms-auto position-relative', s.value)} title={`${roundedValue}`}>
        {/* <span className={cx('text-truncate pointer', s.tooltip)}>{roundedValue}</span>
        <span className={s.tooltiptext}>
          {roundedValue}
        </span> */}
        {sensorValuesLoading ? <Icon name="faSpinner" spin /> :
          (typeof roundedValue === 'number' ? (
            <>
              <span className={cx('fs-5')}>
                {roundedValue}
              </span>
              <span className={cx('fs-7')}>
                {(symbol && symbol !== 'Other') ? symbol : null}
              </span>
            </>
          ) : '-'
          )}
      </div>
      <div className="ms-1">
        {currentStatus ? <Icon name={currentStatus.icon} size="xl" /> : <div style={{ width: '21.59px' }} />}
      </div>
    </div>
  )
}

export default Insight

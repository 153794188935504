import { useMemo } from 'react'
import { Icon } from '@wiz/components'
import Link from '@/components/Link'

export default function CellEdgeDevice ({
  deviceId,
  list,
}) {
  const edgeDevice = useMemo(() => {
    if (deviceId && list?.data?.items?.length) {
      const next = list.data.items.find(item => item.deviceId === deviceId)
      return next
    }
    return undefined
  }, [ deviceId, list ])
  if (list?.isLoading) {
    return (
      <Icon
        name="fa--spinner"
        spin
      />
    )
  }
  return deviceId ? (
    <div className="min-w-0 d-flex">
      {edgeDevice ? (
        <Link
          className="min-w-0 text-truncate"
          title={deviceId}
          name="edge-device-view"
          params={{ id: edgeDevice?.id }}
        >
          {deviceId}
        </Link>
      ) : (
        <span className="text-truncate">
          {deviceId}
        </span>
      )}
    </div>
  ) : '-'
}

import {
  useMemo, useCallback, useState, useEffect, useContext, lazy, Suspense,
} from 'react'
import cx from 'classnames'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useRouter, useLocationQuery } from '@/router'
import {
  VirtualTable, Pagination, withPagination, withSort,
} from '@wiz/components'
import { useIntl } from '@wiz/intl'
import { consts } from '@wiz/utils'
import events from '@/utils/events'
import { wizataApi } from '@/api'
import { useAuth } from '@/auth'
import Icon from '@/shared/icon'
import withTableActions from '@/containers/withTableActions'
import UpdatedInfo from '@/components/Form/UpdatedInfo'
import FormExecution from '@/components/Forms/Execution'
import CellTwin from '@/components/Template/components/cellTwin'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import useAppContext from '@/hooks/useAppContext'
import CellTime from './CellTime'
import CellTemplate from './CellTemplate'
import CellEdgeDevice from './CellEdgeDevice'
import CellTrigger from './CellTrigger'
import s from './index.module.css'

const ChartsDrawer = lazy(() => import('@/components/ChartsDrawer'))

const experimentsMap = {
  [consts.ExperimentFilterType.isNull]: true,
  [consts.ExperimentFilterType.isNotNull]: false,
  [consts.ExperimentFilterType.all]: 'all',
}

const SortByFields = {
  createdDate: 'createdDate',
  updatedDate: 'updatedDate',
  name: 'name',
  waitingTime: 'waitingTime',
  executionTime: 'executionTime',
  startedDate: 'startedDate',
  queuedDate: 'queuedDate',
}

const SortDirection = {
  asc: 'Ascending',
  desc: 'Descending',
}

const Table = ({
  columns, data, filters, ...props
}) => {
  const auth = useAuth()
  const intl = useIntl()

  if (props.loading && !data) {
    return (
      <div className="position-absolute-fill position-center-fill bg-light opacity-50">
        <Icon name="faSpinner" size="xl" spin />
      </div>
    )
  }

  return (
    <>
      <VirtualTable
        {...props}
        className="flex-fill mb-3"
        placeholder={intl.t('errors.noDataDisplay')}
        columns={columns}
        data={data?.items}
        items={data?.items}
        filters={filters}
        selection={auth.checkAccessManage('SectionHistoricalRuns')}
      />

      <Pagination
        {...props}
        total={data?.totalCount}
        items={data?.items}
        filters={filters}
      />
    </>
  )
}

const TableComponent = withSort()(
  withPagination()(Table),
)

const CellExternalLink = ({
  id,
  list,
  name,
  page,
  embed,
}) => {
  const router = useRouter()
  const query = useLocationQuery()
  const onClick = () => {
    if (!embed) {
      router.push({ name: `${page}-list`, query: { [name]: id } })
    } else if (name) {
      router.push({ query: { ...query, page, [name]: id } })
    }
  }

  const item = useMemo(() => {
    if (id && list?.data?.length) {
      const next = list.data.find(pipeline => pipeline.id === id)
      return next
    }
    return undefined
  }, [ id, list ])
  if (list?.isLoading) {
    return (
      <Icon
        name="faSpinner"
        spin
      />
    )
  }

  return id ? (
    <div className="min-w-0 d-flex">
      {item ? (
        <a
          className="text-truncate pointer"
          onClick={() => onClick()}
          aria-hidden
        >
          <span>{item.name || item.key}</span>
        </a>
      ) : '-'}

    </div>
  ) : '-'
}

const Executions = ({
  filters, onFilter, onResetFilter, templateId, exclude, ...props
}) => {
  const [ editId, setEditId ] = useState(null)
  const [ warnings, setWarnings ] = useState([])
  const auth = useAuth()
  const intl = useIntl()
  const { contextTwinId } = useGlobalExecute()

  const { filters: contextFilters, onRowSelect } = useAppContext()

  const {
    data: executions, isLoading, refetch, isError, error, isRefetching, isFetching,
  } = useQuery({
    queryKey: [ 'executionsList', contextFilters.dateFrom + contextFilters.dateTo ],
    queryFn: () => {
      const limit = filters.pageSize + 1
      const offset = (filters.page - 1) * filters.pageSize

      const nextFilters = []
      const complexFilters = {}
      if (templateId || contextFilters.templateId) {
        nextFilters.push({
          propertyName: 'templateId',
          operationType: consts.FilterOperationType.Equals,
          type: (templateId || contextFilters.templateId) === 'null' ? consts.FilterType.Guid : consts.FilterType.Enum,
          canBeNull: (templateId || contextFilters.templateId) === 'null' ? true :
            !(templateId || contextFilters.templateId),
          ...((templateId || contextFilters.templateId) && (templateId || contextFilters.templateId) !== 'null') ?
            { value: templateId || contextFilters.templateId } : {},
        })
      }
      if (contextFilters.pipelineId) {
        nextFilters.push({
          propertyName: 'pipelineId',
          operationType: consts.FilterOperationType.Equals,
          type: contextFilters.pipelineId === 'null' ? consts.FilterType.Guid : consts.FilterType.Enum,
          canBeNull: contextFilters.pipelineId === 'null' ? true : !contextFilters.pipelineId,
          ...(contextFilters.pipelineId && contextFilters.pipelineId !== 'null') ?
            { value: contextFilters.pipelineId } : {},
        })
      }
      if (contextFilters.executionTriggerId) {
        nextFilters.push({
          propertyName: 'executionTriggerId',
          type: consts.FilterType.Guid,
          operationType: consts.FilterOperationType.Equals,
          value: contextFilters.executionTriggerId,
        })
      }
      if (filters.twinId || contextTwinId) {
        complexFilters.twinId = filters.twinId || contextTwinId
        complexFilters.includeChildren = true
      }
      if (contextFilters.status) {
        nextFilters.push({
          propertyName: 'status',
          type: consts.FilterType.Enum,
          operationType: consts.FilterOperationType.Equals,
          value: contextFilters.status,
        })
      }
      if (contextFilters.experiments && contextFilters.experiments !== 'all') {
        const canBeNull = experimentsMap[contextFilters.experiments]
        nextFilters.push({
          propertyName: 'experimentId',
          type: consts.FilterType.Guid,
          operationType: consts.FilterOperationType.Equals,
          value: null,
          canBeNull,
        })
      }

      // is null -> CanBeNull = true
      // is not null -> CanBeNull = false и в value тоже ничего не ставь, оставь его null
      if (contextFilters.dateFrom && contextFilters.dateTo) {
        nextFilters.push({
          propertyName: 'CreatedDate',
          type: consts.FilterType.UnixTimestamp,
          operationType: consts.FilterOperationType.Between,
          start: contextFilters.dateFrom,
          end: contextFilters.dateTo,
        })
      }

      return wizataApi.entities.getList('executions', {
        pagination: {
          take: limit - 1,
          skip: offset,
        },
        sortingList: [
          {
            direction: SortDirection[filters.sortDir],
            propertyName: SortByFields[filters.sortBy],
          },
        ],
        filters: nextFilters,
        ...complexFilters,
      })
      // .then((data) => {
      //   const { items, totalCount } = data || {}
      //   setTotal(totalCount)
      //   const _items = items.slice(0, filters.pageSize)
      //   setItems(_items)
      // })
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!contextFilters.dateTo && !!contextFilters.experiments,
  })

  const edgeDevices = useQuery({
    queryKey: [ 'edgeDevices' ],
    queryFn: () => wizataApi.edgeDevices.getList({
      pagination: {
        take: 555,
        skip: 0,
      },
      sortingList: [
        {
          direction: 'Ascending',
          propertyName: 'createdDate',
        },
      ],
      filters: [],
    }),
    enabled: true,
  })

  const pipelines = useQuery({
    queryKey: [ 'pipelines' ],
    queryFn: () => wizataApi.pipelines.getList(),
    enabled: true,
    refetchOnWindowFocus: false,
    retry: false,
  })

  const templates = useQuery({
    queryKey: [ 'templates' ],
    queryFn: () => wizataApi.templates.getList('false'),
    enabled: true,
    refetchOnWindowFocus: false,
    retry: false,
  })

  const triggers = useQuery({
    queryKey: [ 'triggers' ],
    queryFn: () => wizataApi.triggersDS.getList(),
    enabled: true,
    refetchOnWindowFocus: false,
    retry: false,
  })

  const handleCopy = async (value) => {
    await navigator.clipboard.writeText(value)
    events.emit('app:notify', {
      type: 'success',
      title: 't/executions.copyTitle',
      duration: 1,
      rawMessage: 't/executions.warningCopied',
    })
  }

  const loadWarning = useCallback(async (id) => {
    const isWarningExist = warnings.some(wrn => wrn.id === id)

    if (!isWarningExist && id) {
      try {
        const currentWarning = await wizataApi.executionsCore.getWarningById(id)
        setWarnings([ ...warnings, currentWarning ])
      } catch (err) {
        throw new Error('error loading Execution warning', err)
      }
    }
  }, [ warnings ])

  const renderCurrentWarning = useCallback((id) => {
    const foundWarning = warnings.find(wrn => wrn.id === id)
    return foundWarning?.warning
  }, [ warnings ])

  const columns = useMemo(() => [
    {
      accessor: 'id',
      disableResizing: true,
      disableSortBy: true,
      maxWidth: 30,
      className: 'justify-content-center text-nowrap',
      Cell: ({ cell, row }) => (auth.checkAccessRead('HistoricalRun') ? (
        <button
          type="button"
          className="btn btn-sm p-0"
          onClick={() => setEditId(cell.value)}
        >
          <Icon name="faEye" />
        </button>
      ) : null),
    },
    {
      Header: intl.t('form.fields.pipeline'),
      accessor: 'pipelineId',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 80,
      Cell: ({ row, cell }) => (
        <CellExternalLink
          id={cell.value}
          list={pipelines}
          name="pipelineId"
          page="pipelines"
          embed={props.embed}
        />
      ),
    },
    {
      Header: intl.t('form.fields.twin'),
      accessor: 'twinId',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 80,
      Cell: ({ row, cell }) => (
        <CellTwin twinId={cell.value} />
      ),
    },
    {
      Header: intl.t('form.fields.trigger'),
      accessor: 'executionTriggerId',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 80,
      Cell: ({ row, cell }) => (
        <CellTrigger id={cell.value} list={triggers} />
      ),
    },
    {
      Header: intl.t('form.fields.template'),
      accessor: 'templateId',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 80,
      Cell: ({ row, cell }) => (
        <CellTemplate id={cell.value} list={templates} />
      ),
    },
    {
      Header: intl.t('form.fields.edgeDevice'),
      accessor: 'edgeDeviceId',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 80,
      maxWidth: 100,
      Cell: ({ cell }) => <CellEdgeDevice deviceId={cell.value} list={edgeDevices} />,
    },
    {
      Header: intl.t('form.fields.status'),
      accessor: 'status',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 80,
      maxWidth: 100,
    },
    {
      Header: intl.t('form.fields.warnings'),
      accessor: 'warnings',
      disableSortBy: true,
      minWidth: 120,
      maxWidth: 200,
      Cell: ({ row, cell }) => (
        <div
          className="position-relative min-w-0 d-flex"
          onClick={() => handleCopy(renderCurrentWarning(row?.original?.executionWarningId) || cell.value)}
          onMouseOver={() => loadWarning(row?.original?.executionWarningId)}
          aria-hidden
        >
          <span className={cx('text-truncate pointer', s.tooltip)}>{cell.value}</span>
          <span className={s.tooltiptext}>
            {renderCurrentWarning(row?.original?.executionWarningId) || cell.value}
          </span>
        </div>
      ),
    },
    {
      Header: intl.t('form.fields.waitingTime'),
      accessor: 'waitingTime',
      disableResizing: true,
      maxWidth: 100,
      Cell: ({ row, cell }) => (
        <CellTime
          value={cell.value}
          parts="days,hours,minutes,seconds"
        />
      ),
    },
    {
      Header: intl.t('form.fields.executionTime'),
      accessor: 'executionTime',
      disableResizing: true,
      width: 100,
      Cell: ({ row, cell }) => (
        <CellTime
          value={cell.value}
          parts="days,hours,minutes,seconds"
        />
      ),
    },
    {
      Header: intl.t('form.fields.startedDate'),
      accessor: 'startedDate',
      disableResizing: true,
      minWidth: 190,
      width: 190,
      maxWidth: 190,
      Cell: ({ cell, row }) => (
        <UpdatedInfo
          date={cell.value}
          // userId={row.original.createdById}
        />
      ),
    },
    {
      Header: intl.t('form.fields.queuedDate'),
      accessor: 'queuedDate',
      disableResizing: true,
      minWidth: 190,
      width: 190,
      maxWidth: 190,
      Cell: ({ cell, row }) => (
        <UpdatedInfo
          date={cell.value}
          // userId={row.original.createdById}
        />
      ),
    },
  ], [ intl, pipelines, templates, triggers, props.embed, auth, loadWarning, renderCurrentWarning ])

  const itemsList = useMemo(() => ({
    pipelineId: pipelines?.data,
    templateId: templates?.data,
    executionTriggerId: triggers?.data,
  }), [ pipelines, templates, triggers ])

  const drawerSequence = [
    {
      name: 'status',
      chart: consts.PanelChartType.pie,
    },
    {
      name: 'pipelineId',
      chart: consts.PanelChartType.bar,
    },
    {
      name: 'twinId',
      chart: consts.PanelChartType.bar,
    },
    {
      name: 'templateId',
      chart: consts.PanelChartType.pie,
    },
    {
      name: 'experiments',
      chart: consts.PanelChartType.pie,
    },
    {
      name: 'executionTriggerId',
      chart: consts.PanelChartType.bar,
    },
  ]

  const possibleFilters = useMemo(() => ({
    pipelineId: contextFilters.pipelineId,
    status: contextFilters.status,
    dateFrom: contextFilters.dateFrom,
    dateTo: contextFilters.dateTo,
    experiments: contextFilters.experiments,
    templateId: contextFilters.templateId,
    executionTriggerId: contextFilters.executionTriggerId,
    twinId: contextTwinId,
  }), [ contextFilters, contextTwinId ])

  useEffect(() => {
    if (executions && !isRefetching) {
      refetch()
    }
  }, [
    filters.page,
    filters.pageSize,
    filters.sortDir,
    filters.sortBy,
    contextFilters.pipelineId,
    contextFilters.status,
    contextFilters.dateFrom,
    contextFilters.experiments,
    contextFilters.templateId,
    contextFilters.executionTriggerId,
    contextTwinId,
  ])

  if (isError) {
    return (
      <div className="position-center-fill flex-column h-100">
        <h4>{intl.t('executions.loadingError')}</h4>
        <p className="text-danger">{error.message}</p>
        <button
          type="button"
          className="btn btn-fill-secondary"
          onClick={onResetFilter}
        >
          {intl.t('form.actions.refresh')}
        </button>
      </div>
    )
  }

  return (
    <div className="d-flex flex-fill flex-column min-h-0 overflow-hidden">
      <div className="d-flex flex-fill flex-column min-h-0 pt-3 pb-md-3 px-3">
        <TableComponent
          data={executions}
          columns={columns}
          filters={filters}
          loading={isLoading || isRefetching}
          {...props}
          onSelect={onRowSelect}
        />
      </div>
      {editId ? (
        <FormExecution
          id={editId}
          onClose={() => setEditId(null)}
          dialog={{
            title: intl.t('executions.titleView'),
            dataTestid: 'executionViewDialog',
          }}
        />
      ) : null}
      <Suspense fallback={<div />}>
        <ChartsDrawer
          filters={possibleFilters}
          valuesList={[ 'waitingTime', 'executionTime' ]}
          sequence={drawerSequence}
          entity="executions"
          itemsList={itemsList}
          includeChildren
        />
      </Suspense>
    </div>
  )
}

export default withTableActions({
  defaultFilters () {
    return {
      sortBy: 'queuedDate',
      sortDir: 'desc',
    }
  },
})(
  Executions,
)

import { useFormContext, Controller } from 'react-hook-form'
import { FormField, FormControl, FormFieldInline } from '@wiz/components'
import { get, consts } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

export default function Fields ({ scope, id }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <>
      <FormField
        label={intl.t('form.fields.id')}
        description={intl.t('edgeDevices.form.fields.consumerIdDescr')}
        errors={get(errors, `${scope}.id`)}
      >
        <FormControl
          name={`${scope}.id`}
          rules={{
            required: intl.t('form.errors.fieldRequired'),
            maxLength: {
              value: 32,
              message: intl.t('form.errors.fieldMaxlen', { max: 32 }),
            },
          }}
          disabled={id}
        />
      </FormField>

      <FormField
        label={intl.t('form.fields.type')}
        description={intl.t('edgeDevices.form.fields.consumerTypeDescr')}
        errors={get(errors, `${scope}.type`)}
      >
        <FormControl
          name={`${scope}.type`}
          disabled={id}
        />
      </FormField>

      <FormFieldInline
        label={intl.t('form.fields.configJSON')}
        description={intl.t('edgeDevices.form.fields.consumerJsonDescr')}
        errors={get(errors, `${scope}.configJSON`)}
        vertical
        complex
      >
        <FormControl
          type="code"
          lang="json"
          title={intl.t('form.fields.code')}
          description={intl.t('scripts.form.fields.codeDescr')}
          name={`${scope}.configJSON`}
          rules={{
            validate: (value) => {
              const str = String(value || '').trim()
              if (!str.length) {
                return intl.t('form.errors.fieldRequired')
              }
              if (value?.length > 20000) {
                return intl.t('form.errors.fieldMaxlen', { max: 20000 })
              }
              return true
            },
          }}
        />
      </FormFieldInline>
    </>
  )
}

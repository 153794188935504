import { useMemo } from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { wizataApi } from '@/api'
import FormDialog from '@/components/Form/FormDialog'
import events from '@/utils/events'
import { EdgeDevicesEntity } from '@/utils/consts'
import Section from './Section'
import Removed from '../../Removed'

export default function EdgeDevicesTrigger ({
  id,
  deviceId,
  dialog,
  onClose,
  onSubmit,
}) {
  const queryClient = useQueryClient()

  const { data: items, isLoading } = useQuery({
    queryKey: [ 'edgeDeviceTriggers', deviceId ],
    queryFn: () => wizataApi.edgeDevices.getConfigs(deviceId, EdgeDevicesEntity.triggers),
    enabled: !!deviceId,
    staleTime: Infinity,
  })

  const {
    mutateAsync: mutateEdgeDevicesConfig,
  } = useMutation({
    mutationKey: [ 'mutateEdgeDevicesTriggers' ],
    mutationFn: (data) => {
      const reqParams = {
        deviceId,
        content: data?.content,
        configType: EdgeDevicesEntity.triggers,
      }
      if (!id && !items) {
        return wizataApi.edgeDevices.createConfigs(reqParams)
      }
      return wizataApi.edgeDevices.updateConfigs(reqParams)
    },
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/edgeDevices.triggers.update.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'edgeDeviceTriggers', deviceId ] })
    },
  })

  const handleSubmit = async (data) => {
    const parsedData = {
      id: data?.edgeDevicesConfig?.id,
      type: data?.edgeDevicesConfig?.type,
      ...JSON.parse(data?.edgeDevicesConfig?.configJSON.replace(/\\n/, '')),
    }
    const param = {
      content: JSON.stringify(parsedData, null, 2),
    }
    await mutateEdgeDevicesConfig(param)
  }

  const defaultValues = useMemo(() => {
    if (!id || !Array.isArray(items) || !items.length) {
      return
    }

    const existedItem = items.find(el => el.id === id)
    const mappedItem = {
      id: existedItem?.id,
      type: existedItem?.type,
      configJSON: JSON.stringify(existedItem, null, 2),
    }
    return { edgeDevicesConfig: mappedItem }
  }, [ items, id ])

  if (id && !items) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={handleSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="edgeDeviceTrigger"
      dialog={dialog}
    >
      <Section
        scope="edgeDevicesConfig"
        id={id}
      />
    </FormDialog>
  )
}

import {
  forwardRef, useImperativeHandle, Fragment, useRef,
} from 'react'
import {
  useFormContext,
  useFieldArray,
  Controller,
} from 'react-hook-form'
import classnames from 'classnames'
import { has } from '@wiz/utils'
import { FormInputNumber } from '@wiz/components'
import Icon from '@/shared/icon'
import { useIntl } from '@wiz/intl'
import { severities, conditionTypes } from './utils'
import SelectSeverity from './SelectSeverity'
import s from './index.module.css'

const defaultRowValues = {
  type: 'gte',
  value: '',
  // severity: '',
}

const Fields = forwardRef(({
  scope,
}, ref) => {
  const intl = useIntl()
  const { register, formState: { errors } } = useFormContext()
  const {
    fields, append, remove, insert,
  } = useFieldArray({
    name: scope,
    keyName: '_id',
    shouldUnregister: true,
  })

  useImperativeHandle(ref, () => ({
    add () {
      append(defaultRowValues)
    },
    addInitial () {
      append([{ ...defaultRowValues, severity: 3 }, { value: null, type: null, severity: 0 }])
    },
    insertField (idx, rows) {
      insert(idx, rows)
    },
  }))

  return (
    <div className="list-group list-group-flush flex-fill mx-0">
      {fields?.map((item, idx) => {
        const scopeRow = `${scope}.${idx}`
        return (
          <Fragment key={item.id || item._id}>
            <div
              key={item.id}
              className="list-group-item py-2 px-0 d-flex align-items-center"
            >
              <input
                disabled
                style={{ width: 44 }}
                className="bg-transparent border-0 shadow-none text-black"
                value={idx === 0 ? 'If' : idx === (fields?.length || 0) - 1 ? 'Else' : 'Else If'}
              />
              <input
                {...register(`${scopeRow}.name`, {
                  validate: (value) => {
                    const str = String(value || '').trim()
                    if (!str.length) {
                      return intl.t('form.errors.fieldRequired')
                    }
                    if (value.length > 450) {
                      return intl.t('form.errors.fieldMaxlen', { max: 450 })
                    }
                    return true
                  },
                })}
                type="search"
                className={classnames('form-control me-2', {
                  'is-invalid': has(errors, `${scopeRow}.name`),
                })}
                placeholder={intl.t('insights.form.fields.condition.namePlaceholder')}
              />
              {idx !== fields.length - 1 ? (
                <>
                  <select
                    {...register(`${scopeRow}.type`, {
                      required: intl.t('form.errors.fieldRequired'),
                    })}
                    className={classnames('form-select me-2', {
                      'is-invalid': has(errors, `${scopeRow}.type`),
                    })}
                    style={{ width: 80 }}
                  >
                    {conditionTypes.map(id => (
                      <option key={id} value={id}>{intl.t(`enum.conditionType.${id}`)}</option>
                    ))}
                  </select>
                  <Controller
                    name={`${scopeRow}.value`}
                    render={({ field, fieldState }) => (
                      <FormInputNumber
                        {...field}
                        invalid={fieldState.invalid}
                        scale={8}
                        className={classnames('me-2', s.value)}
                      />
                    )}
                  />
                </>
              ) : null}
              {/* <select
                {...register(`${scopeRow}.severity`, {
                  required: intl.t('form.errors.fieldRequired'),
                })}
                className={classnames('form-select me-1 w-50', {
                  'is-invalid': has(errors, `${scopeRow}.severity`),
                })}
                placeholder={intl.t('insights.form.fields.severity.placeholder')}
              >
                {severities.map(({ id, value }) => (
                  <option key={id} value={id}>{intl.t(`insights.form.fields.severity.${value}`)}</option>
                ))}
              </select> */}
              <SelectSeverity scopeRow={scopeRow} />
              <button
                type="button"
                className="btn btn-flat-secondary btn-sm"
                style={(idx === 0 || idx === fields.length - 1) ? { visibility: 'hidden' } : {}}
                title={intl.t('form.actions.remove')}
                onClick={() => remove(idx)}
                disabled={(idx === 0 || idx === fields.length - 1)}
              >
                <Icon name="faTrash" />
              </button>
            </div>
          </Fragment>
        )
      })}
      {!fields.length ? (
        <div className="list-group-item text-center">
          {intl.t('form.info.noConditions')}
        </div>
      ) : null}
    </div>
  )
})

export default Fields

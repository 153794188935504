import { useMemo } from 'react'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { wizataApi } from '@/api'
import FormDialog from '@/components/Form/FormDialog'
import events from '@/utils/events'
import { consts } from '@wiz/utils'
import Section from './Section'
import Removed from '../../Removed'

export default function EdgeDevicesFunction ({
  id, // function name
  deviceId,
  dialog,
  onClose,
  onSubmit,
}) {
  const queryClient = useQueryClient()

  const { data: item, isLoading } = useQuery({
    queryKey: [ 'edgeDeviceFunction', id ],
    queryFn: () => wizataApi.edgeDevices.getFunction(deviceId, id.split('.')[0]),
    enabled: !!id,
    staleTime: Infinity,
  })

  const {
    mutateAsync: mutateEdgeDevicesFunction,
  } = useMutation({
    mutationKey: [ 'mutateEdgeDevicesFunction' ],
    mutationFn: (data) => {
      const reqParams = {
        deviceId,
        content: data?.content,
        functionName: data?.functionName,
      }
      if (!id) {
        return wizataApi.edgeDevices.createFunctions(reqParams)
      }
      return wizataApi.edgeDevices.updateFunctions({
        ...reqParams,
        functionName: data?.functionName.split('.')[0],
      })
    },
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/edgeDevices.functions.update.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'edgeDeviceFunctions', deviceId ] })
      queryClient.invalidateQueries({ queryKey: [ 'edgeDeviceFunction', id ] })
    },
  })

  const handleSubmit = async (data) => {
    await mutateEdgeDevicesFunction({
      functionName: data?.edgeDevicesConfig?.name,
      content: data?.edgeDevicesConfig?.code,
    })
  }

  const defaultValues = useMemo(() => {
    if (id) {
      return {
        edgeDevicesConfig: {
          name: `${item?.functionName}.py`,
          code: item?.content,
        },
      }
    }
  }, [ item, id ])

  if (id && !item) {
    return <Removed />
  }

  return (
    <FormDialog
      onSubmit={handleSubmit}
      onClose={onClose}
      defaultValues={defaultValues}
      dataTestid="edgeDeviceFunction"
      dialog={dialog}
    >
      <Section
        scope="edgeDevicesConfig"
        id={id}
      />
    </FormDialog>
  )
}

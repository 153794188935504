import { useMemo } from 'react'
import { Twin as Twin$ } from '@wiz/store'
import FormDialog from '@/components/Form/FormDialog'
import { useLocation } from '@/router'
import { CorePages } from '@/utils/consts'
import Removed from '../Removed'
import Section from './Section'
import SectionInsights from './SectionInsights'
import Schema from './Schema'
import resolver from './resolver'

export default function Twin ({
  dialog,
  id,
  onClose,
  onSubmit,
  onSuccess,
  insights = [],
  twin,
  withInsights,
}) {
  const loc = useLocation()
  const [ ,, corePage ] = loc.pathname.split('/')

  const defaultValues = useMemo(() => ({
    twin: {
      ...Twin$.toJSON(twin),
    },
    insights: {
      list: [
        ...insights.map(insight => ({ ...insight, condition: JSON.parse(insight.condition) || [] })),
      ],
      selected: null,
    },
  }), [
    twin,
    insights,
  ])

  if (id && !twin) {
    return <Removed />
  }

  return (
    <FormDialog
      dataTestid="twinForm"
      defaultValues={defaultValues}
      dialog={dialog}
      onClose={onClose}
      onSubmit={onSubmit}
      onSuccess={(res) => {
        if (corePage !== CorePages.OPERATE) {
          onSuccess(res)
        }
      }}
      resolver={resolver}
      schema={Schema}
    >
      <Section scope="twin" />
      {withInsights && id ? <SectionInsights scope="insights" /> : null}
    </FormDialog>
  )
}

import { useFormContext } from 'react-hook-form'
import { FormControl, FormFieldInline } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'

export default function Fields ({ scope, id }) {
  const intl = useIntl()
  const { formState: { errors } } = useFormContext()

  return (
    <FormFieldInline
      label={intl.t('form.fields.configJSON')}
      description={intl.t('edgeDevices.form.fields.triggerCodeDescr')}
      errors={get(errors, `${scope}.configJSON`)}
      vertical
      complex
    >
      <FormControl
        type="code"
        lang="json"
        title={intl.t('form.fields.code')}
        description={intl.t('scripts.form.fields.codeDescr')}
        name={`${scope}.configJSON`}
        rules={{
          validate: (value) => {
            const str = String(value || '').trim()
            if (!str.length) {
              return intl.t('form.errors.fieldRequired')
            }
            if (value?.length > 20000) {
              return intl.t('form.errors.fieldMaxlen', { max: 20000 })
            }
            return true
          },
        }}
      />
    </FormFieldInline>
  )
}

export default function ds (client) {
  return function decorator (target) {
    Object.defineProperties(target.prototype, {
      wizard: {
        enumerable: true,
        value: Object.create({}, {
          getSteps: {
            enumerable: true,
            value ({
              executionId, isanomalydetection, func, script,
            }) {
              let query = ''
              if (executionId) {
                query += `executionId=${executionId}&`
              }
              if (isanomalydetection) {
                query += `isanomalydetection=${isanomalydetection}&`
              }
              if (func) {
                query += `function=${func}&`
              }
              if (script) {
                query += `script=${script}`
              }

              const request = client.get(`/wizard/steps/?${query}`)
              return request.fetch()
            },
          },
          createExperiment: {
            enumerable: true,
            value (params) {
              return client.post('/wizard', { ...params }).fetch()
            },
          },
        }),
      },
      poc: {
        enumerable: true,
        value: Object.create({}, {
          getElementsById: {
            enumerable: true,
            value (id = '70e2f1e7-fe1a-42a4-8d74-446082163439') { // twin id
              return client.get(`/poc/elements/${id}`).fetch()
            },
          },
          getDashboards: {
            enumerable: true,
            value (templateId) {
              return client.get(`/poc/dashboards/${templateId}`).fetch()
            },
          },
        }),
      },
      datapoints: {
        enumerable: true,
        value: Object.create({}, {
          getList: {
            enumerable: true,
            value (twinId) {
              return client.get(`/datapoints/?twinId=${twinId}`).fetch()
            },
          },
        }),
      },
      twins: {
        enumerable: true,
        value: Object.create({}, {
          getList: {
            enumerable: true,
            value () {
              return client.get('/twins/').fetch()
            },
          },
          getById: {
            enumerable: true,
            value (id) {
              return client.get(`/twins/${id}/`).fetch()
            },
          },
        }),
      },
      mlmodelsv2: {
        value: Object.create({}, {
          getList: {
            enumerable: true,
            value () {
              return client.get('/models').fetch()
            },
          },
          getPagedList: {
            enumerable: true,
            value (params) {
              return client.post('/models/paged', { ...params }).fetch()
            },
          },
        }),
      },
      whoami: {
        value: Object.create({}, {
          docs: {
            enumerable: true,
            value (params) {
              return client.post('/whoami/docs', { ...params }).fetch()
            },
          },
        }),
      },
      // engine management endpoints
      engine: {
        value: Object.create({}, {
          info: {
            enumerable: true,
            value (type) {
              let query = ''

              if (type) {
                query += `queue=${type}`
              }
              return client.get(`/pipeline_mgt/info?${query}`).fetch()
            },
          },
          ping: {
            enumerable: true,
            value (type) {
              let query = ''

              if (type) {
                query += `queue=${type}`
              }
              return client.get(`/pipeline_mgt/ping?${query}`).fetch()
            },
          },
          restart: {
            enumerable: true,
            value (type) {
              let query = ''

              if (type) {
                query += `queue=${type}`
              }
              return client.get(`/pipeline_mgt/restart?${query}`).fetch()
            },
          },
          forceRestart: {
            enumerable: true,
            value (type) {
              let query = ''

              if (type) {
                query += `queue=${type}`
              }
              return client.get(`/pipeline_mgt/force_restart?${query}`).fetch()
            },
          },
          pause: {
            enumerable: true,
            value (type) {
              let query = ''

              if (type) {
                query += `queue=${type}`
              }
              return client.get(`/pipeline_mgt/pause?${query}`).fetch()
            },
          },
        }),
      },

    })
  }
}

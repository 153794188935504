import { useMemo } from 'react'
import { Icon } from '@wiz/components'
import Link from '@/components/Link'

export default function CellPipeline ({
  id,
  list,
}) {
  const item = useMemo(() => {
    if (id && list?.data?.length) {
      const next = list.data.find(d => d.id === id)
      return next
    }
    return undefined
  }, [ id, list ])

  if (list?.isLoading) {
    return (
      <Icon
        name="fa--spinner"
        spin
      />
    )
  }

  return id ? (
    <div className="min-w-0 d-flex">
      {item ? (
        <Link
          className="min-w-0 text-truncate"
          title={item?.name || item?.key}
          name="pipelines-list"
          query={{ pipelineId: item?.id }}
        >
          {item.name || item?.key}
        </Link>
      ) : '-'}

    </div>
  ) : '-'
}

import { useState } from 'react'
import classnames from 'classnames'
import { useIntl } from '@wiz/intl'
import FormTwin from '@/containers/Forms/Twin'
import Icon from '../../../shared/icon'

export default function ButtonCreateTwin ({
  className,
  onSuccess,
}) {
  const intl = useIntl()
  const [ isOpen, setOpen ] = useState(false)
  return (
    <>
      <button
        type="button"
        className={classnames('btn btn-fill-secondary-alt text-nowrap ms-1', className)}
        onClick={() => setOpen(true)}
      >
        <Icon name="faAdd" />
      </button>

      {isOpen ? (
        <FormTwin
          onClose={() => setOpen(false)}
          dialog={{
            title: intl.t('twin.titleCreate'),
            dataTestid: 'replaceTwinDialog',
          }}
          onSuccess={onSuccess}
          withNavigate={false}
        />
      ) : null}
    </>
  )
}

import { useCallback, memo, useRef } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dbProvider, Twin } from '@wiz/store'
import { withProps } from '@wiz/components'
import { wizataApi } from '@/api'
import events from '@/utils/events'
import FormTwin from '@/components/Forms/Twin'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import enhanceTwin from './enhanceTwin'
import enhanceTwinData from './enhanceTwinData'

const enhanceProps = withProps(({ withNavigate = true, onSuccess }) => {
  const { handleTwinChange, contextTwinId } = useGlobalExecute()
  const queryClient = useQueryClient()

  const {
    mutateAsync: updateTwin,
  } = useMutation({
    mutationKey: [ 'updateTwin' ],
    mutationFn: data => wizataApi.twinsCore.update(data),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/components.update.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'twinsList' ] })
    },
  })

  const {
    mutateAsync: createTwin,
  } = useMutation({
    mutationKey: [ 'createTwin' ],
    mutationFn: data => wizataApi.twinsCore.create(data),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/components.update.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'twinsList' ] })
    },
  })

  const {
    mutateAsync: mutateInsight,
  } = useMutation({
    mutationKey: [ 'mutateInsight' ],
    mutationFn: (data) => {
      if (data.id) {
        return wizataApi.insights.update(data)
      }
      return wizataApi.insights.create(data)
    },
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/insights.update.error',
        message: err.message,
        duration: 5000,
      })
    },
  })

  const replaceInsight = useCallback(async (twinData, insightData) => {
    const next = {
      name: insightData.name,
      displayPrecision: insightData.displayPrecision,
      sensorId: insightData.sensorId,
      condition: {},
      twinId: twinData.id,
    }
    if (insightData.id && !insightData.id.startsWith('new')) {
      next.id = insightData.id
    }
    if (insightData.condition?.parsed?.length) {
      next.condition.parsed = insightData.condition.parsed
      next.condition.value = ''
    }
    next.condition = JSON.stringify(next.condition)
    const response = await mutateInsight(next)
    return response
  }, [ mutateInsight ])

  const onSubmit = useCallback(async (data) => {
    const context = dbProvider.createBatchContext()
    const model = await dbProvider.prepareReplaceData(context, Twin, data.twin)
    await model.prepareReplaceDashboards(context, data.dashboards)
    await model.prepareReplaceExplorers(context, data.explorers)
    await model.prepareReplaceStreamJobs(context, data.streamJobs)
    await model.prepareReplaceTwinGraphs(context, data.twinGraphs)
    await dbProvider.batch(context)

    if (data.twin.id) {
      await updateTwin(data.twin)
    } else {
      await createTwin({ ...data.twin, id: model?.id })
    }

    if (data?.insights?.list.length) {
      Promise.all(data.insights.list.map(insight => replaceInsight(model, insight))).finally(() => {
        queryClient.invalidateQueries({ queryKey: [ 'insightsListByTwins', contextTwinId ] })
      })
    }

    return model
  }, [ createTwin, updateTwin, replaceInsight, contextTwinId, queryClient ])

  const handleSuccess = useCallback((res) => {
    if (withNavigate) {
      handleTwinChange(res.id)
    }
    onSuccess?.(res)
  }, [ handleTwinChange, withNavigate, onSuccess ])

  return {
    onSubmit,
    onSuccess: handleSuccess,
  }
})

export default memo(
  enhanceTwin(
    enhanceTwinData(
      enhanceProps(FormTwin),
    ),
  ),
)

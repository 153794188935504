import PropTypes from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import { FormField } from '@wiz/components'
import { get } from '@wiz/utils'
import { useIntl } from '@wiz/intl'
import { useAuth } from '@/auth'
import Selectable from '@/components/Form/Selectable'
import SelectTwin from '@/components/Form/SelectTwin'

export default function FieldsTwins ({ scope }) {
  const intl = useIntl()
  const auth = useAuth()
  const { formState: { errors } } = useFormContext()

  return auth.checkAccessRead('Twin') ? (
    <FormField
      complex
      label={intl.t('form.fields.twins')}
      description={intl.t('users.form.fields.twinsDescr')}
      errors={get(errors, scope)}
      selectable={() => <Selectable hidden value="twins" />}
    >
      <Controller
        name={scope}
        render={({ field }) => (
          <SelectTwin
            {...field}
            placeholder={intl.t('form.fields.twinPlaceholder')}
            multiselect
          />
        )}
      />
    </FormField>
  ) : null
}

FieldsTwins.propTypes = {
  scope: PropTypes.string.isRequired,
}

FieldsTwins.defaultProps = {}

export const PagesMap = {
  Dashboards: 'dashboards',
  BusinessLabels: 'business_labels',
  Templates: 'templates',
  Components: 'components',
  Experiments: 'experiments',
  Executions: 'executions',
  StreamJobs: 'stream_jobs',
  StreamJobsLogs: 'stream_jobs_logs',
  SMSLogs: 'sent_sms',
  EmailLogs: 'sent_email',
  Pipelines: 'pipelines-list',
  Triggers: 'triggers',
  Sensors: 'sensors',
  Categories: 'categories',
  Units: 'units',
  Labels: 'labels',
  Scripts: 'scripts',
  MlModels: 'mlmodels',
  Plots: 'plots',
  Twins: 'items', // old legacy route name
  GroupSystem: 'group-systems',
  EdgeDevices: 'edge-devices',
  EngineManagement: 'engine-management',
}

export const PagesWithFilters = [
  PagesMap.Components,
  PagesMap.Experiments,
  PagesMap.Executions,
  PagesMap.StreamJobs,
  PagesMap.StreamJobsLogs,
  PagesMap.SMSLogs,
  PagesMap.EmailLogs,
  PagesMap.Pipelines,
  PagesMap.Triggers,
  PagesMap.Sensors,
]

export const CorePages = {
  OPERATE: 'controlpanel',
  DESIGN: 'ailab',
  TWIN: 'datahub',
}

export const DataHubPages = {
  [PagesMap.Sensors]: {
    id: 1,
    route: PagesMap.Sensors,
    hasFilters: true,
    hasSearch: true,
    hasBulkDelete: false,
    hasDelete: true,
    hasAdd: true,
    hasDuplicate: false,
    hasRefresh: true,
    queryKey: 'sensorsPagedList',
    permission: 'SectionDigitalTwinSensors',
  },
  [PagesMap.Categories]: {
    id: 2,
    route: PagesMap.Categories,
    hasFilters: false,
    hasSearch: true,
    hasBulkDelete: false,
    hasDelete: true,
    hasAdd: true,
    hasDuplicate: false,
    hasRefresh: true,
    queryKey: 'categoriesList',
    permission: 'SectionDigitalTwinCategories',
  },
  [PagesMap.Units]: {
    id: 3,
    route: PagesMap.Units,
    hasFilters: false,
    hasSearch: true,
    hasBulkDelete: false,
    hasDelete: true,
    hasAdd: true,
    hasDuplicate: false,
    hasRefresh: true,
    queryKey: 'unitsList',
    permission: 'SectionDigitalTwinUnits',
  },
  [PagesMap.Labels]: {
    id: 4,
    route: PagesMap.Labels,
    hasFilters: false,
    hasSearch: true,
    hasBulkDelete: false,
    hasDelete: true,
    hasAdd: true,
    hasDuplicate: false,
    hasRefresh: true,
    queryKey: 'labelsList',
    permission: 'SectionLabels',
  },
  [PagesMap.Twins]: {
    id: 5,
    route: PagesMap.Twins,
    hasFilters: true,
    hasSearch: true,
    hasBulkDelete: false,
    hasDelete: true,
    hasAdd: true,
    hasDuplicate: false,
    hasRefresh: false, // set to true when api ready
    // queryKey: 'twinsList',
    permission: 'SectionDigitalTwinItems',
  },
}

export const AiLabPages = {
  [PagesMap.Templates]: {
    id: 10,
    route: PagesMap.Templates,
    hasFilters: false,
    hasSearch: true,
    hasBulkDelete: false,
    hasDelete: true,
    hasAdd: true,
    hasDuplicate: false,
    hasRefresh: true,
    queryKey: 'templatesPagedList',
    permission: 'SectionTemplates',
  },
  [PagesMap.Components]: {
    id: 11,
    route: PagesMap.Components,
    hasFilters: true,
    hasSearch: true,
    hasBulkDelete: false,
    hasDelete: true,
    hasAdd: false,
    hasDuplicate: false,
    hasRefresh: true,
    queryKey: 'componentsList',
    permission: 'SectionComponents',
  },
  [PagesMap.BusinessLabels]: {
    id: 12,
    route: PagesMap.BusinessLabels,
    hasFilters: false,
    hasSearch: true,
    hasBulkDelete: false,
    hasDelete: true,
    hasAdd: true,
    hasDuplicate: false,
    hasRefresh: false,
    queryKey: 'businessLabelsList',
    permission: 'SectionBusinessLabels',
  },
  [PagesMap.Dashboards]: {
    id: 13,
    route: PagesMap.Dashboards,
    hasFilters: false,
    hasSearch: true,
    hasBulkDelete: false,
    hasDelete: true,
    hasAdd: true,
    hasDuplicate: false,
    hasRefresh: false,
    queryKey: 'dashboardsList',
    permission: 'SectionDashboards',
  },
  [PagesMap.Pipelines]: {
    id: 14,
    route: PagesMap.Pipelines,
    hasFilters: true,
    hasSearch: true,
    hasBulkDelete: false,
    hasDelete: true,
    hasAdd: true,
    hasDuplicate: false,
    hasRefresh: true,
    queryKey: 'pipelinesList',
    permission: 'SectionPipelines',
  },
  [PagesMap.Scripts]: {
    id: 15,
    route: PagesMap.Scripts,
    hasFilters: false,
    hasSearch: true,
    hasBulkDelete: false,
    hasDelete: true,
    hasAdd: false,
    hasDuplicate: false,
    hasRefresh: false,
    // queryKey: 'scriptsList',
    permission: 'SectionExecutionScripts',
  },
  [PagesMap.MlModels]: {
    id: 16,
    route: PagesMap.MlModels,
    hasFilters: false,
    hasSearch: false,
    hasBulkDelete: false,
    hasDelete: false,
    hasAdd: false,
    hasDuplicate: false,
    hasRefresh: true,
    queryKey: 'mlmodelsList',
    permission: 'SectionExperiments', // FIXME: change when done
  },
  [PagesMap.Plots]: {
    id: 17,
    route: PagesMap.Plots,
    hasFilters: false,
    hasSearch: true,
    hasBulkDelete: false,
    hasDelete: true,
    hasAdd: false,
    hasDuplicate: false,
    hasRefresh: true,
    queryKey: 'plotsList',
    permission: 'SectionPlots',
  },
  [PagesMap.Triggers]: {
    id: 18,
    route: PagesMap.Triggers,
    hasFilters: true,
    hasSearch: false,
    hasBulkDelete: false,
    hasDelete: true,
    hasAdd: true,
    hasDuplicate: false,
    hasRefresh: true,
    queryKey: 'triggersPagedList',
    permission: 'SectionTriggers',
  },
  [PagesMap.Experiments]: {
    id: 19,
    route: PagesMap.Experiments,
    hasFilters: true,
    hasSearch: true,
    hasBulkDelete: false,
    hasDelete: true,
    hasAdd: false,
    hasDuplicate: false,
    hasRefresh: true,
    queryKey: 'experimentsList',
    permission: 'SectionExperiments',
  },
  [PagesMap.Executions]: {
    id: 20,
    route: PagesMap.Executions,
    hasFilters: true,
    hasSearch: true,
    hasBulkDelete: false,
    hasDelete: true,
    hasAdd: false,
    hasDuplicate: false,
    hasRefresh: true,
    queryKey: 'executionsList',
    permission: 'SectionHistoricalRuns',
  },
  [PagesMap.StreamJobs]: {
    id: 21,
    route: PagesMap.StreamJobs,
    hasFilters: true,
    hasSearch: true,
    hasBulkDelete: false,
    hasDelete: true,
    hasAdd: true,
    hasDuplicate: false,
    hasRefresh: false,
    // queryKey: 'componentsList',
    permission: 'SectionStreamJobs',
  },
  [PagesMap.Alerts]: {
    id: 22,
    route: PagesMap.Alerts,
    hasFilters: false,
    hasSearch: false,
    hasBulkDelete: false,
    hasDelete: true,
    hasAdd: true,
    hasDuplicate: false,
    hasRefresh: false,
    // queryKey: 'alertsList',
    permission: 'SectionAlerts',
  },
  [PagesMap.StreamJobsLogs]: {
    id: 23,
    route: PagesMap.StreamJobsLogs,
    hasFilters: true,
    hasSearch: false,
    hasBulkDelete: false,
    hasDelete: true,
    hasAdd: true,
    hasDuplicate: false,
    hasRefresh: false,
    // queryKey: 'sjLogsList',
    permission: 'SectionStreamJobLogs',
  },
  [PagesMap.SMSLogs]: {
    id: 24,
    route: PagesMap.SMSLogs,
    hasFilters: true,
    hasSearch: false,
    hasBulkDelete: false,
    hasDelete: true,
    hasAdd: true,
    hasDuplicate: false,
    hasRefresh: false,
    // queryKey: 'smsLogsList',
    permission: 'SectionSentSMSLogs',
  },
  [PagesMap.EmailLogs]: {
    id: 25,
    route: PagesMap.EmailLogs,
    hasFilters: true,
    hasSearch: false,
    hasBulkDelete: false,
    hasDelete: true,
    hasAdd: true,
    hasDuplicate: false,
    hasRefresh: false,
    queryKey: 'emailLogsList',
    permission: 'SectionSentEmailLogs',
  },
  [PagesMap.GroupSystem]: {
    id: 26,
    route: PagesMap.GroupSystem,
    hasFilters: false,
    hasSearch: true,
    hasBulkDelete: false,
    hasDelete: true,
    hasAdd: true,
    hasDuplicate: false,
    hasRefresh: false,
    queryKey: 'groupSystemsList',
    permission: 'SectionExperiments',
  },
  [PagesMap.EdgeDevices]: {
    id: 27,
    route: PagesMap.EdgeDevices,
    hasFilters: false,
    hasSearch: true,
    hasBulkDelete: false,
    hasDelete: true,
    hasAdd: true,
    hasDuplicate: false,
    hasRefresh: true,
    queryKey: 'edgeDevicesList',
    permission: 'SectionExperiments', // change to edge device permissions
  },
  [PagesMap.EngineManagement]: {
    id: 28,
    route: PagesMap.EngineManagement,
    hasFilters: false,
    hasSearch: false,
    hasBulkDelete: false,
    hasDelete: false,
    hasAdd: false,
    hasDuplicate: false,
    hasRefresh: false,
    queryKey: 'engineManagement',
    permission: 'SectionPipelines', // change to runners permissions
  },
}

export const BOARD_VIEW_ENUM = {
  GLOBAL: 'global',
  PERSONAL: 'personal',
  ORGANIZATIONAL: 'org',
}
export const SEVERITY_ENUM = {
  CRITICAL: 'critical',
  WARNING: 'warning',
  SUCCESS: 'success',
  ALL: 'all',
  INFO: 'info',
}

export const SEVERITY_STATUS_MAP = [
  {
    id: 3, name: 'Informative', color: '#F6F4FB', code: SEVERITY_ENUM.INFO,
  },
  {
    id: 2, name: 'Success / positive', color: '#7CFFB2', code: SEVERITY_ENUM.SUCCESS, icon: 'faCircleCheck',
  },
  {
    id: 1, name: 'Warning', color: '#FDDD60', code: SEVERITY_ENUM.WARNING, icon: 'faDiamondExclamation',
  },
  {
    id: 0, name: 'Critical / Error', color: '#D71818', code: SEVERITY_ENUM.CRITICAL, icon: 'faTriangleExclamation',
  },
]

export const EdgeDeviceStatus = {
  WaitingForRegistration: 'WaitingForRegistration',
  Deploying: 'Deploying',
  Deployed: 'Deployed',
  Registered: 'Registered',
  Failed: 'Failed',
  Offline: 'Offline',
}

export const EdgeDeviceStatusColorsScheme = {
  [EdgeDeviceStatus.WaitingForRegistration]: '#D1E2EE',
  [EdgeDeviceStatus.Deploying]: '#6FE7F7',
  [EdgeDeviceStatus.Deployed]: '#7CFFB2',
  [EdgeDeviceStatus.Registered]: '#FDDD60',
  [EdgeDeviceStatus.Failed]: '#D71818',
  [EdgeDeviceStatus.Offline]: '#D1E2EE',
}

export const EdgeDevicesEntity = {
  functions: 'functions',
  consumers: 'consumers',
  writers: 'writers',
  triggers: 'triggers',
}

export const RunnerStatus = {
  pausing: 'pausing',
  starting: 'starting',
  listening: 'listening',
  running: 'running',
  terminating: 'terminating',
}

export const RunnerStatusColorsScheme = {
  [RunnerStatus.pausing]: '#FDDD60',
  [RunnerStatus.starting]: '#FDDD60',
  [RunnerStatus.listening]: '#7CFFB2',
  [RunnerStatus.running]: '#7CFFB2',
  [RunnerStatus.terminating]: '#D71818',
}

export const RunnerConnectionType = {
  Connectivity: 'Connectivity',
  ConnectivityAndPipelines: 'ConnectivityAndPipelines',
}

import { withObservables } from '@wiz/components'
import { dbProvider } from '@wiz/store'

const BackArrow = ({ settings }) => (
  <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.26315 5.95617C-0.0877168 5.59763 -0.0877168 5.01537 0.26315 4.65683L0.260344 4.65397L4.53852 0.268903C4.88939 -0.0896343 5.4592 -0.0896343 5.81006 0.268903C6.16093 0.62744 6.16093 1.2097 5.81006 1.56824L3.10447 4.34865H10.2567C12.3241 4.34865 14 6.06145 14 8.17432V8.44211C14 10.4071 12.4414 12 10.5187 12C10.0225 12 9.62032 11.5889 9.62032 11.0818C9.62032 10.5747 10.0225 10.1637 10.5187 10.1637C11.449 10.1637 12.2032 9.3929 12.2032 8.44211V8.17432C12.2032 7.07563 11.3317 6.18496 10.2567 6.18496H3.03014L5.81006 9.02773C6.16093 9.38627 6.16093 9.96853 5.81006 10.3271C5.4592 10.6856 4.88939 10.6856 4.53852 10.3271L0.26315 5.95617Z" fill={settings.EnvironmentColor || '#E74914'} />
  </svg>

)

const enhanceProps = withObservables([], () => ({
  settings: dbProvider.observeGlobalSettings([ 'EnvironmentColor' ]),
}))

export default enhanceProps(BackArrow)

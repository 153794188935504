import { useMemo, useState, useCallback } from 'react'
import { useIntl } from '@wiz/intl'
import {
  FormInputSearch,
  TableWidget,
  FormSelectDateTimeRange,
  GrafanaStaticRanges,
} from '@wiz/components'
import Select from '@/components/Form/Select'
import FormatDateTime from '@/containers/FormatDateTime'
import Icon from '@/shared/icon'
import { ModulesTypes } from './utils'

const EdgeLogs = ({
  data, isLoading, onTimestampChange, timestamp, modules, onRefresh,
}) => {
  const [ search, setSearch ] = useState()
  const [ filters, setFilters ] = useState({})
  const [ isTouched, setIsTouched ] = useState(false)
  const intl = useIntl()

  const columns = useMemo(() => [
    {
      Header: intl.t('form.fields.deviceId'),
      customHeaderStyles: {
        backgroundColor: '#031E37',
      },
      accessor: 'appId',
      disableResizing: true,
      disableSortBy: true,
      maxWidth: 200,
      Cell: ({ cell }) => <span className="text-truncate">{cell.value}</span>,
    },
    {
      Header: intl.t('form.fields.message'),
      customHeaderStyles: {
        backgroundColor: '#031E37',
      },
      accessor: 'message',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 220,
      Cell: ({ cell }) => <span className="text-truncate">{cell.value}</span>,
    },
    {
      Header: intl.t('form.fields.level'),
      customHeaderStyles: {
        backgroundColor: '#031E37',
      },
      accessor: 'level',
      disableResizing: true,
      disableSortBy: true,
      width: 15,
      Cell: ({ cell }) => <span className="text-truncate">{cell.value}</span>,
    },
    {
      Header: intl.t('form.fields.timestamp'),
      customHeaderStyles: {
        backgroundColor: '#031E37',
      },
      accessor: 'timestamp',
      disableResizing: true,
      disableSortBy: true,
      minWidth: 200,
      width: 200,
      maxWidth: 220,
      Cell: ({ cell }) => (
        <FormatDateTime
          date={Date.now(cell.value)}
          dateFormat="yy-MM-dd HH:mm:ss"
          withMillis={false}
          withSecond
        />
      ),
    },
  ], [ intl ])

  const handleRefresh = useCallback(() => {
    if (timestamp.label) {
      const ts = GrafanaStaticRanges.find(staticRange => staticRange.label === timestamp.label)
      if (ts) {
        const r = ts.range()

        onTimestampChange({ from: r[0], to: r[1], label: timestamp.label })
      }
    } else {
      onRefresh()
    }
  }, [ onRefresh, onTimestampChange, timestamp.label ])

  const actionButtons = useMemo(() => (
    <div className="d-flex">
      <div className="ms-2 d-flex">
        <button
          type="button"
          className="btn btn-fill-secondary-alt btn-text"
          onClick={handleRefresh}
          disabled={isLoading}
        >
          <Icon
            name="faArrowsRotate"
            spin={isLoading}
          />
        </button>
      </div>
      <div className="ms-2">
        <FormInputSearch
          placeholder={intl.t('form.actions.searchPlaceholder')}
          onChange={(value) => {
            // router.replace({ query: { view, search: value } })
            setSearch(value)
          }}
          className="form-control-sm bg-light-alt"
          timeout={500}
        />
      </div>
      {modules ? (
        <div className="ms-2">
          <Select
            placeholder={intl.t('form.fields.moduleIdPlaceholder')}
            options={modules?.map((module) => {
              if (ModulesTypes[module.moduleId]) {
                return {
                  id: ModulesTypes[module.moduleId], name: ModulesTypes[module.moduleId],
                }
              }
              return undefined
            }).filter(Boolean)}
            value={filters.moduleId}
            onChange={moduleId => setFilters({ moduleId })}
            size="sm"
            controlPrefix="Module"
            initialOptionStyle
            noRemove
            disabled={isLoading}
          />
        </div>
      ) : null}
      <div className="ms-2">
        <Select
          placeholder={intl.t('form.fields.levelPlaceholder')}
          options={Array.from({ length: 6 }).map((_, idx) => ({ id: idx + 1, value: idx + 1, name: idx + 1 }))}
          value={filters.level}
          onChange={level => setFilters({ level })}
          size="sm"
          controlPrefix="Level"
          initialOptionStyle
          noRemove
          disabled={isLoading}
        />
      </div>
      <div className="ms-2 d-flex">
        <FormSelectDateTimeRange
          value={(
          timestamp.from && timestamp.to ?
            [ timestamp.from, timestamp.to, timestamp.label ] :
            [ null, null ]
        )}
          DateTimeComponent={FormatDateTime}
          size="sm"
          showArrows={false}
          isTouched={isTouched}
          setIsTouched={setIsTouched}
          disabled={isLoading}
          grafana
          grafanaDefaultValue="Last 1 hour"
          onChange={([ from, to, label ]) => {
            onTimestampChange({ from, to, label })
          }}
        />
      </div>
    </div>
  ), [ intl, isLoading, isTouched, timestamp, onTimestampChange, filters, modules, handleRefresh ])

  const items = useMemo(() => {
    if (!data?.totalCount) {
      return []
    }
    const next = [ ...data.items ]

    if (search || filters.level) {
      return next.filter((log) => {
        let isMatch = true
        if (search) {
          isMatch = log.appId?.toLowerCase()?.includes(search.toLowerCase()) ||
                    log.message?.toLowerCase()?.includes(search.toLowerCase())
        }
        if (filters.level) {
          isMatch = +log.level <= filters.level
        }
        if (filters.moduleId) {
          isMatch = log.appId === filters.moduleId
        }
        return isMatch
      })
    }

    return next
  }, [ data, search, filters ])

  if (!data) {
    return null
  }

  return (
    <TableWidget
      title={intl.t('edgeDevices.common.logs')}
    // titleIcon={<LegacyIcon className="me-2" name="wiz--edge--modules" width={20} height={20} />}
      actionBar={actionButtons}
      tableColumns={columns}
      tableData={items}
      loading={isLoading}
    />
  )
}

export default EdgeLogs

import Icon from '@/shared/icon'

const Loading = ({ children }) => (
  <div className="position-absolute-fill position-center-fill bg-light-alt opacity-50 d-flex flex-column">
    <Icon name="faSpinner" size="xl" spin />
    {children}
  </div>
)

export default Loading

import { useCallback, useMemo } from 'react'
import cx from 'classnames'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  withObservables, Icon as LegacyIcon, CustomScrollbars, useMobile,
} from '@wiz/components'
import { useLocationQuery } from '@/router'
import { dbProvider, Q } from '@wiz/store'
import Icon from '@/shared/icon'
import camelCase from 'lodash/camelCase'
import { useGlobalExecute } from '@/context/GlobalExecuteProvider'
import useAppContext from '@/hooks/useAppContext'
import useAuthComponents from '@/pages/operate/hooks'
import { SEVERITY_ENUM } from '@/utils/consts'
import { wizataApi } from '@/api'
import { useAuth } from '@/auth'
import { useIntl } from '@wiz/intl'
import events from '@/utils/events'
import Insight from './insight'
import s from '../index.module.css'

const iconByTwinType = {
  area: { icon: 'fa--warehouse', color: 'twinColorAreas' },
  machine: { icon: 'fa--cogs', color: 'twinColorMachines' },
  equipment: { icon: 'fa--tools', color: 'twinColorEquipment' },
}

const CardView = ({
  options, onChange, isComponent, onEdit, sensors, sensorValues, units, twinSettings,
}) => {
  const { severities } = useAppContext()
  const { contextTwinId } = useGlobalExecute()

  const auth = useAuth()
  const intl = useIntl()
  const query = useLocationQuery()
  const isMobile = useMobile()
  const { checkAvailability } = useAuthComponents()

  const queryClient = useQueryClient()

  const {
    mutateAsync: addFavorite,
  } = useMutation({
    mutationKey: [ 'addFavorite' ],
    mutationFn: id => wizataApi.dashboardsComponents.addFavorite(id),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/insights.update.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'componentsFavorites', contextTwinId ] })
    },
  })
  const {
    mutateAsync: deleteFavorite,
  } = useMutation({
    mutationKey: [ 'deleteFavorite' ],
    mutationFn: id => wizataApi.dashboardsComponents.deleteFavorite(id),
    onError: (err) => {
      events.emit('app:notify', {
        type: 'error',
        title: 't/insights.update.error',
        message: err.message,
        duration: 5000,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ 'componentsFavorites', contextTwinId ] })
    },
  })

  const handleFavorite = useCallback((item) => {
    if (item?.isFavorite) {
      deleteFavorite(item.id)
    } else {
      addFavorite({
        entityId: item.id,
        entityType: isComponent ? 'components' : 'twins',
      })
    }
  }, [ addFavorite, deleteFavorite, isComponent ])

  // const sortedOptions = useMemo(() => {
  //   if (options?.length) {
  //     return options.sort((a, b) => a.name.localeCompare(b.name))
  //   }
  //   return []
  // }, [ options ])

  return options?.map((item) => {
    if (query.search && !item.name.toLowerCase().includes(query.search?.toLowerCase())) {
      return null
    }
    const insights = item.insights?.map((ins) => {
      const sensor = sensors?.find(snsr => snsr.id === ins.sensorId)
      const unit = units?.find(unt => unt.id === sensor?.unitId)
      if (!sensor) {
        return ins
      }
      return {
        ...ins,
        hardwareId: sensor.hardwareId,
        symbol: unit?.symbol,
      }
    }).sort((a, b) => a.name.localeCompare(b.name))
    const hasInsights = item.insights?.length
    const isErrorState = hasInsights && item.insights.find(i => severities[item.id]?.[i.id] === SEVERITY_ENUM.CRITICAL)

    const canCreateInsight = isComponent ? checkAvailability(auth.currentUser.id === item.ownerId) :
      auth.checkAccessManage('Twin')

    return (
      <div
        key={item.id}
        className={cx('', s.card, {
          'mb-2': isComponent,
          [s.error]: isErrorState,
          [s.cardMobile]: isMobile,
          [s.noData]: isComponent && !hasInsights,
          [s.olNone]: isComponent,
        })}
        onClick={() => onChange(isComponent ? item : item.id)}
        aria-hidden
      >
        {isComponent ? null : (
          <div className={cx(s.cardImg, {
            [s.cardIcon]: !item.image,
          })}
          >
            {!isMobile && !item.image && !isErrorState && auth.checkAccessManage('Twin') ? (
              <div
                className={s.cardImgAddBtn}
                onClick={(e) => {
                  e.stopPropagation()
                  onEdit({ item, isComponent })
                }}
                aria-hidden
              >
                <Icon
                  name="faCamera"
                  size="md"
                  className="me-1"
                />
                <Icon
                  name="faPlus"
                  size="md"
                />
              </div>
            ) : null}
            {!isErrorState ? (
              item.image ? (
                <img
                  src={item.image}
                  alt={item.name}
                />
              ) : (
                <>
                  {!item.icon && iconByTwinType[item.type] ? (
                    <LegacyIcon
                      name={iconByTwinType[item.type].icon}
                      color={twinSettings[iconByTwinType[item.type].color]}
                      size="3X"
                      className="m-auto"
                    />
                  ) : null}
                  {item.icon && !item?.icon?.includes('icon') ? item?.icon?.includes('cus-') ? (
                    <LegacyIcon
                      name={item.icon}
                      color={item.color}
                      size="3X"
                      className="m-auto"
                    />
                  ) : (
                    <Icon
                      name={camelCase(item.icon)}
                      color={item.color}
                      size="3x"
                      className="m-auto"
                    />
                  ) :
                    null}
                </>
              )
            ) :
                <Icon className="m-auto" name="faTriangleExclamation" size="3x" />}
            {/* <div className={cx('card-action-open position-absolute', s.hover)}>Open</div> */}
          </div>
        )}
        <div
          className={s.cardBody}
          // onMouseEnter={() => setContentShown(item.id)}
          // onMouseLeave={() => setContentShown(null)}
        >
          <div className={cx('fw-bold d-flex align-items-center', s.pCard, {
            [s.cardTitle]: isComponent,
          })}
          >
            {!item.icon && iconByTwinType[item.type] ? (
              <LegacyIcon
                name={iconByTwinType[item.type].icon}
                color={twinSettings[iconByTwinType[item.type].color]}
                size="md"
                className="me-2"
              />
            ) : null}
            {(item.icon && !item?.icon?.includes('icon')) ? item?.icon?.includes('cus-') ? (
              <LegacyIcon
                name={item.icon}
                color={item.color}
                size="md"
                className="me-2"
              />
            ) : (
              <Icon
                name={camelCase(item.icon)}
                color={item.color}
                size="md"
                className="me-2"
                width={18}
              />
            ) : null}
            <span className="text-truncate flex-fill" style={{ maxWidth: '78%' }}>{item.name}</span>
            <div className="ms-auto d-flex align-items-center">
              <Icon
                name="faStar"
                size="md"
                className={s.favIcon}
                width={18}
                color={item.isFavorite ? '#fff' : isComponent ? '#01101D' : '#184980'}
                onClick={(e) => {
                  e.stopPropagation()
                  handleFavorite(item)
                }}
              />
              {!isMobile &&
              ((isComponent ? checkAvailability(auth.currentUser.id === item.ownerId) :
                auth.checkAccessManage('Twin'))) ? (
                  <Icon
                    name="faPenToSquare"
                    className={s.editIcon}
                    size="md"
                    width={11}
                    onClick={(e) => {
                      e.stopPropagation()
                      onEdit({ item, isComponent })
                    }}
                  />
                ) : null}
            </div>
          </div>
          {insights?.length ? (
            <CustomScrollbars
              className={cx('flex-fill', { 'd-none': !hasInsights })}
              classNameContent={cx('d-flex flex-column', s.pInsight)}
              horizontal={false}
              shadow={false}
            >
              <div>
                {insights.map(({ id, ...rest }) => (
                  <Insight
                    key={id}
                    id={id}
                    className="mt-1"
                    {...rest}
                    sensorValues={sensorValues}
                    cardId={item.id}
                  />
                ))}
              </div>
            </CustomScrollbars>
          ) : canCreateInsight ? (
            <div className={cx('w-auto h-auto mx-auto my-2 d-none', {
              'my-auto': !isComponent,
              // 'd-none': isComponent && contentShown !== item.id,
            })}
            >
              <button
                type="button"
                className={cx('btn btn-fill-secondary-alt btn-text me-2', {})}
                onClick={(e) => {
                  e.stopPropagation()
                  if (canCreateInsight) {
                    onEdit({ item, isComponent })
                  }
                }}
              >
                <Icon name="faAdd" className="me-2" />
                {intl.t('insights.form.actions.create')}
              </button>
            </div>
          ) : null}
          {item.description ? (
            <div className={cx('fs-7', s.description)}>
              {item.description}
            </div>
          ) : null}
        </div>
      </div>
    )
  })
}

const enhance = withObservables([ 'options' ], ({ options }) => {
  const sensorIds = options.reduce((acc, val) => {
    if (val.insights?.length) {
      val.insights.forEach(ins => ins.sensorId && acc.push(ins.sensorId))
    }
    return acc
  }, [])

  return {
    settings: dbProvider.observeGlobalSettings([ 'EnvironmentColor' ]),
    units: dbProvider.database.collections.get('units').query().observeWithColumns([ 'updated_at' ]),
    sensors: dbProvider.database.collections.get('sensors').query(
      Q.where('id', Q.oneOf(sensorIds)),
    ).observeWithColumns([ 'updated_at' ]),
    twinSettings: dbProvider.observeGlobalSettings([
      'twinColorAreas',
      'twinColorMachines',
      'twinColorEquipment',
    ]),
  }
})

export default enhance(CardView)

import {
  useEffect, useMemo, useRef, useState,
} from 'react'
import cx from 'classnames'
import { useLocationQuery } from '@/router'
import { appEnv } from '@/config'
import { useIntl } from '@wiz/intl'
import { Skeleton } from '@wiz/components'
import DashboardView from '@/containers/Dashboards/View'
import { consts } from '@wiz/utils'
import Icon from '@/shared/icon'
import { useAuth } from '@/auth'
import s from './index.module.css'

const GRAFANA_BASE_URL = 'https://grafana-poc.onwizata.com/' // should be replaced by using /check
const DS_API_RECENT_URL = 'https://dev-wizard.onwizata.com'
const { hostname } = new URL(appEnv.DS_API_RECENT_URL || DS_API_RECENT_URL)

const Grafana = ({
  content, formData, isEdit, check, dashboardId, type, variables = [], refreshInterval,
}) => {
  const [ grafanaVar, setGrafanaVar ] = useState({})
  const [ showGrafanaLogin, setShowGrafanaLogin ] = useState(true)
  const [ token, setToken ] = useState()

  const refIframe = useRef(null)
  const refBef = useRef()
  const refTimeout = useRef()
  const refInterval = useRef()

  const {
    theme, from, to, defaultFrom, defaultTo,
  } = useLocationQuery()
  const intl = useIntl()
  const auth = useAuth()

  const generateToken = async () => {
    const _token = await auth.acquireToken()
    setToken(_token)
  }

  useEffect(() => {
    generateToken()
  }, [])

  useEffect(() => {
    if (variables.length) {
      const defaultGrafanaVars = variables.reduce((acc, val) => {
        if (val?.type === 'select') {
          return val.defaults.reduce((acm, defVal) => {
            const propertyArr = Array.isArray(acm[val?.name]) ? [ ...acm[val?.name] ] : []
            return { ...acm, [val?.name]: [ ...propertyArr, `&var-${val?.name}=${defVal}`.replace(/\s/g, '%20') ] }
          }, acc)
        }
        const urlParam = `&var-${val?.name}=${val?.defaults?.[0]}`.replace(/\s/g, '%20')
        return { ...acc, [val?.name]: urlParam }
      }, {})
      setGrafanaVar(defaultGrafanaVars)
    }
  }, [ variables ])

  const grafanaURL = useMemo(() => {
    let url = ''

    if (content) {
      url += (check?.data || GRAFANA_BASE_URL) + content
    }
    if (content?.includes('?')) {
      url += '&'
    } else {
      url += '?'
    }
    if (!isEdit) {
      url += 'kiosk&'
    }
    if (refreshInterval) {
      const interval = consts.GrafanaStepRequests.find(step => step.id === refreshInterval)
      if (interval) {
        url += `refresh=${interval.name}&`
      }
    }

    return url
  }, [ content, isEdit, check?.data, refreshInterval ])

  const streamlitURL = useMemo(() => {
    let url = ''

    if (content) {
      url += content
    }
    url += `?dsapi=${hostname}&auth_token=${token}`

    return url
  }, [ content, token ])

  const varParams = variables.length ? Object.values(grafanaVar).join('').replace(/,/g, '') : ''

  const datesExtension = useMemo(() => {
    if (defaultFrom && defaultTo) {
      return `&from=${defaultFrom}&to=${defaultTo}`
    }
    if (from && to) {
      if (from.startsWith('now')) {
        return `&from=${from}&to=${to}`
      }
      if (to === 'now') {
        return `&from=${to}-${from}&to=${to}`
      }
      return `&from=${from}&to=${to}`
    }
    if (formData.dateFrom && formData.dateTo) {
      return `&from=${formData.dateFrom}&to=${formData.dateTo}`
    }

    return ''
  }, [ formData, from, to, defaultFrom, defaultTo ])

  const fullGrafanaUrl = `${grafanaURL}theme=${theme || 'dark'}${varParams}${datesExtension}&_dash.hideTimePicker`

  const openGrafana = (link = fullGrafanaUrl) => {
    const instance = window.open(link)
    refTimeout.current = setTimeout(() => {
      instance.close()
      setShowGrafanaLogin(false)
    }, 1000)
  }

  useEffect(() => {
    if (type === 'Grafana' && refreshInterval && !refInterval.current) {
      refInterval.current = setInterval(() => {
        // FIXME: actually just triggering component to rerender
        setGrafanaVar({})
      }, refreshInterval)
    }

    return () => clearInterval(refInterval.current)
  }, [])

  if (type === 'Dashboard') {
    return (
      <div className={cx('d-flex position-relative', s.wrap)}>
        <div ref={refBef} className="d-flex flex-column w-100">
          <DashboardView
            match={{ params: { id: dashboardId } }}
            embed
            embedWidth={refBef.current?.offsetWidth}
            rightbar={isEdit}
            isEditable={isEdit}
            dynamicWidth
          />
        </div>
      </div>
    )
  }

  if (type === 'Iframe') {
    return (
      <>
        {auth.currentUser?.needIframeLoginAssistance && showGrafanaLogin ? (
          <div className="mt-2 d-flex align-items-center ms-4">
            {/* {intl.t('grafana.openTitle')} */}
            If you cannot see iframe dashboard below, please click on Open button
            <button
              type="button"
              className="btn btn-fill-secondary-alt btn-text ms-2"
              onClick={() => openGrafana(content)}
            >
              Open
            </button>
            <Icon name="faXmark" onClick={() => setShowGrafanaLogin(false)} className="pointer ms-1" />
          </div>
        ) : null}
        <iframe
          title="iframe component"
          className={s.twin}
          src={content}
          frameBorder="0"
        />
      </>
    )
  }

  if (type === 'Streamlit') {
    return (
      <>
        {auth.currentUser?.needIframeLoginAssistance && showGrafanaLogin ? (
          <div className="mt-2 d-flex align-items-center ms-4">
            {/* {intl.t('grafana.openTitle')} */}
            If you cannot see streamlit dashboard below, please click on Open button
            <button
              type="button"
              className="btn btn-fill-secondary-alt btn-text ms-2"
              onClick={() => openGrafana(streamlitURL)}
            >
              Open
            </button>
            <Icon name="faXmark" onClick={() => setShowGrafanaLogin(false)} className="pointer ms-1" />
          </div>
        ) : null}
        <iframe
          title="iframe component"
          className={s.twin}
          src={streamlitURL}
          frameBorder="0"
        />
      </>
    )
  }

  return (check?.isLoading && check?.isFetching) ? <Skeleton width="100%" height="100%" /> : (
    <>
      {auth.currentUser?.needIframeLoginAssistance && showGrafanaLogin ? (
        <div className="mt-2 d-flex align-items-center ms-4">
          {/* {intl.t('grafana.openTitle')} */}
          If you cannot see grafana dashboard below, please click on Open button
          <button
            type="button"
            className="btn btn-fill-secondary-alt btn-text ms-2"
            onClick={() => openGrafana()}
          >
            Open
          </button>
          <Icon name="faXmark" onClick={() => setShowGrafanaLogin(false)} className="pointer ms-1" />
        </div>
      ) : null}

      <iframe
        ref={refIframe}
        title="grafana"
        className={s.twin}
        src={fullGrafanaUrl}
        frameBorder="0"
      />
    </>
  )
}

export default Grafana

// export function nestAndFlatten (array) {
//   array.sort((a, b) => a.name.localeCompare(b.name))

//   const rootNodes = [ ...array ]

//   rootNodes.forEach((item) => {
//     if (item.parentId) {
//       const parentNode = rootNodes.find(node => node.id === item.parentId)
//       if (parentNode) {
//         if (!parentNode.children) {
//           parentNode.children = []
//         }
//         parentNode.children.push(item)

//         const index = rootNodes.indexOf(item)
//         if (index > -1) {
//           rootNodes.splice(index, 1)
//         }
//       }
//     }
//   })

//   function flattenNestedStructure (nested) {
//     const result = []
//     const seenIds = new Set()

//     function flatten (node) {
//       if (seenIds.has(node.id)) {
//         return
//       }

//       result.push(node)
//       seenIds.add(node.id)

//       if (node.children && node.children.length > 0) {
//         node.children.forEach(child => flatten(child))
//       }
//     }

//     nested.forEach(rootNode => flatten(rootNode))

//     return result
//   }

//   const flattenedArray = flattenNestedStructure(rootNodes)

//   return flattenedArray
// }

export function organizeAndFlatten (data) {
  // Step 1: Sort by name
  data.sort((a, b) => a.name.localeCompare(b.name))

  // Step 2: Create a mapping of objects by id
  const map = new Map()
  data.forEach((obj) => {
    obj.children = []
    map.set(obj.id, obj)
  })

  // Step 3: Organize parent-child relationships
  const roots = []
  data.forEach((obj) => {
    if (obj.parentId && map.has(obj.parentId)) {
      map.get(obj.parentId).children.push(obj)
    } else {
      roots.push(obj)
    }
  })

  // Step 4: Remove duplicates from children
  function removeDuplicates (node, seen = new Set()) {
    node.children = node.children.filter((child) => {
      if (seen.has(child.id)) return false
      seen.add(child.id)
      return true
    })
    node.children.forEach(child => removeDuplicates(child, seen))
  }
  roots.forEach(root => removeDuplicates(root))

  // Step 5: Flatten structure
  const result = []
  function flatten (node) {
    result.push(node)
    node.children.forEach(flatten)
  }
  roots.forEach(flatten)

  // Remove children arrays from final result
  return result.map(({ children, ...rest }) => rest)
}

import {
  useCallback,
  useEffect,
  useRef,
  memo,
} from 'react'
import { Icon, DragProvider, withObservables } from '@wiz/components'
import { dbProvider } from '@wiz/store'
import {
  Routes,
  Switch,
  Route,
  RouterObserver,
  Redirect,
  createLocation,
} from '@/router'
import { useAuth } from '@/auth'
import { ThemeProvider } from '@/theme'
import { ErrorBoundary } from '@/utils/analytics'
import { SpotlightProvider } from '@/context/SpotlightProvider'
import { GlobalExecuteProvider } from '@/context/GlobalExecuteProvider'
import useDefaultRedirect from '@/hooks/useDefaultRedirect'
import { Stonly } from '@/integrations/stonly'
import Dialogs from '@/components/Dialogs'
import Header from '@/components/Header'
import Notify from '@/components/Header/Notify'
import StatusBar from '@/components/StatusBar'
import DSRedirect from '@/components/Redirect'
import DocsRedirect from '@/components/Redirect/docs'
import AuthDocsRedirect from '@/components/Redirect/auth-docs'

import AccessRoleList from '@/containers/AccessRole/List'
import AlertList from '@/containers/Alert/List'
import BusinessLabelsList from '@/containers/BusinessLabel/List'
import CategoryList from '@/containers/Category/List'
import DashboardsList from '@/containers/Dashboards/List'
import DashboardView from '@/containers/Dashboards/View'
import DataExplorerView from '@/containers/DataExplorer/View'
import EdgeDevicesList from '@/containers/EdgeDevices/List'
import EdgeDevicesView from '@/components/EdgeDevices/View'

import EdgeDeviceCommandList from '@/containers/EdgeDeviceCommand/List'
import EdgeDeviceCommandTemplateList from '@/containers/EdgeDeviceCommandTemplate/List'
import EdgeDeviceList from '@/containers/EdgeDevice/List'
import EdgeDeviceView from '@/containers/EdgeDevice/View'
import EdgeLogsList from '@/containers/EdgeLogs/List'
import EdgeTelemetryList from '@/containers/EdgeTelemetry/List'
import EventList from '@/containers/Event/List'
import ExperimentsList from '@/containers/Experiment/List'
import ExperimentsView from '@/containers/ExperimentResult'
import ExportResultList from '@/containers/ExportResult/List'
import FilesList from '@/containers/File/List'
import FormulaList from '@/containers/Formula/List'
import JobsList from '@/containers/Job/List'
import IntegrationsList from '@/containers/Integrations/List'
import LabelList from '@/containers/Label/List'
import MlWebServiceList from '@/containers/MlWebService/List'
import NotebookList from '@/containers/Notebook/List'
import NotificationSheetList from '@/containers/NotificationSheet/List'
import NotificationsList from '@/containers/Notification/List'
import ProfileView from '@/containers/Profile/View'
import ProjectList from '@/containers/Project/List'
import ProjectView from '@/containers/Project/View'
import QualityDataList from '@/containers/QualityDataList/List'
import ScriptList from '@/containers/Script/List'
import SensorList from '@/containers/Sensor/List'
import SentEmailLogsList from '@/containers/SentEmailLogs/List'
import SentSMSLogsList from '@/containers/SentSMSLogs/List'
import SettingsView from '@/containers/Settings/View'
import StreamJobList from '@/containers/StreamJob/List'
import StreamJobLogsList from '@/containers/StreamJobLogs/List'
import StreamJobView from '@/containers/StreamJob/View'
import TmplNotebooksList from '@/containers/TmplNotebook/List'
import TmplQualityDataList from '@/containers/TmplQualityDataList/List'
import TwinGraphList from '@/containers/TwinGraph/List'
import TwinGraphView from '@/containers/TwinGraph/View'
import TwinList from '@/containers/Twin/List'
import TwinStyleView from '@/containers/TwinStyle/View'
import UnitList from '@/containers/Unit/List'
import UserHomepage from '@/containers/User/Homepage'
import UserList from '@/containers/User/List'
import UserLogout from '@/containers/User/Logout'
import PlotList from '@/containers/Plot/List'
import DataframeList from '@/containers/Dataframe/List'
import MLModelList from '@/containers/MLModel/List'
import ExecutionScriptList from '@/containers/ExecutionScript/List'
import Templates from '@/components/Templates'
import Template from '@/components/Template'
import DashboardsComponents from '@/components/DashboardsComponents'
import HistoricalRunList from '@/components/HistoricalRuns'
import TriggerList from '@/components/Triggers'
import PipelineList from '@/components/Pipelines'
import Pipeline from '@/components/Pipelines/pipeline'
import EngineManagement from '@/components/EngineManagement'
// import Design from '@/pages/design'
import Operate from '@/pages/operate'
import MainWrapper from '@/pages'
import GroupSystemList from '@/containers/GroupSystem/List'
import NotFound from './NotFound'

const RouteComponents = {
  'alerts-list': AlertList,
  'business-labels-list': BusinessLabelsList,
  'categories-list': CategoryList,
  'chart-list': TwinGraphList,
  'chart-view': TwinGraphView,
  'data-observation': DataExplorerView,
  de: DataExplorerView,
  'edge-command-templates-list': EdgeDeviceCommandTemplateList,
  'edge-commands-list': EdgeDeviceCommandList,
  'edge-device-list': EdgeDeviceList,
  'edge-device': EdgeDeviceView,
  'edge-logs-list': EdgeLogsList,
  'edge-telemetry-list': EdgeTelemetryList,
  'events-list': EventList,
  'experiments-list': ExperimentsList,
  'exportresult-list': ExportResultList,
  'files-list': FilesList,
  'formulas-list': FormulaList,
  'jobs-list': JobsList,
  'integrations-list': IntegrationsList,
  'labels-list': LabelList,
  'edge-devices-list': EdgeDevicesList,
  'edge-device-view': EdgeDevicesView,
  'group-systems-list': GroupSystemList,
  'ml-web-services-list': MlWebServiceList,
  'not-found': NotFound,
  'notebooks-list': NotebookList,
  'notification-sheets-list': NotificationSheetList,
  'notifications-list': NotificationsList,
  'projects-list': ProjectList,
  'projects-view': ProjectView,
  'quality-list': QualityDataList,
  'roles-list': AccessRoleList,
  'scripts-list': ScriptList,
  'sensors-list': SensorList,
  'sent-email-logs-list': SentEmailLogsList,
  'sent-sms-logs-list': SentSMSLogsList,
  'stream-job-logs-list': StreamJobLogsList,
  'stream-jobs-list': StreamJobList,
  'stream-jobs-view': StreamJobView,
  'tmpl-notebooks-list': TmplNotebooksList,
  'tmpl-quality-data-list': TmplQualityDataList,
  'twin-items-list': TwinList,
  'twin-style': TwinStyleView,
  'units-list': UnitList,
  'users-list': UserList,
  dashboard: DashboardView,
  dashboards: DashboardsList,
  homepage: UserHomepage,
  logout: UserLogout,
  profile: ProfileView,
  experiment: ExperimentsView,
  settings: SettingsView,
  'plots-list': PlotList,
  'executions-list': HistoricalRunList,
  'dataframes-list': DataframeList,
  'ml-models-list': MLModelList,
  'execution-scripts-list': ExecutionScriptList,
  dsapi: DSRedirect,
  docs: DocsRedirect,
  'auth-docs': AuthDocsRedirect,
  'templates-list': Templates,
  template: Template,
  'components-list': DashboardsComponents,
  'triggers-list': TriggerList,
  'pipelines-list': PipelineList,
  pipeline: Pipeline,
  overview: EngineManagement,
  // design: Design,
  controlpanel: Operate,
}

function PrivateViewProvider ({ children }) {
  return (
    <GlobalExecuteProvider>
      <ThemeProvider>
        <SpotlightProvider>
          <DragProvider>
            {children}
          </DragProvider>
        </SpotlightProvider>
      </ThemeProvider>
    </GlobalExecuteProvider>
  )
}

const enhanceData = withObservables([], () => ({
  featureFlags: dbProvider.observeFlags([ 'StreamJobs' ]),
}))

function PrivateView ({ featureFlags }) {
  const refResetError = useRef()
  const auth = useAuth()
  const handleChangeRoute = useCallback(() => {
    refResetError.current?.()
    refResetError.current = undefined
  }, [])

  useDefaultRedirect('/:lang?')
  // useStonly(auth)

  useEffect(() => {
    document.getElementById('startup-loader').hide()
  }, [])

  const routes = Routes.filter((item) => {
    if (item.name?.startsWith('stream') && !featureFlags.StreamJobs) {
      return null
    }
    return item.private &&
    (RouteComponents[item.name] || item.redirect) &&
    (!item.access || auth.checkAccessRead(item.access) || auth.checkAccessOrganization(item.access))
  })

  return (
    <PrivateViewProvider>
      <RouterObserver onChange={handleChangeRoute} />
      <Notify />
      <Stonly />
      <div className="d-flex flex-fill min-h-0 min-w-0">
        <div className="d-flex flex-column flex-fill min-w-0">
          <Header />

          <MainWrapper>
            <ErrorBoundary
              fallback={({ resetError }) => {
                refResetError.current = resetError
                return (
                  <div className="position-center-fill">
                    <Icon name="fa--bug" size="2X" className="text-danger" />
                  </div>
                )
              }}
            >
              <Switch>
                {routes.map(item => (
                  item.redirect ? (
                    <Redirect
                      key={item.path}
                      from={item.path}
                      to={createLocation(item.redirect)}
                      exact
                    />
                  ) : (
                    <Route
                      key={item.path}
                      path={item.path}
                      component={RouteComponents[item.name]}
                      exact
                    />
                  )
                ))}
              </Switch>
            </ErrorBoundary>
          </MainWrapper>

          <StatusBar />
        </div>
      </div>

      <Dialogs />
    </PrivateViewProvider>
  )
}

export default memo(enhanceData(PrivateView), () => (true))

import { CustomScrollbars, useMobile } from '@wiz/components'
import cx from 'classnames'
import RunnersWidget from './RunnersWidget'
import classes from './index.module.css'

const Runners = {
  pipeline: {
    title: 't/engineManagement.pipelines.title',
    type: 'pipeline',
    icon: 'wiz--menu--engine-management',
  },
  experiment: {
    title: 't/engineManagement.experiments.title',
    type: 'experiment',
    icon: 'wiz--menu--experiment',
  },
}

const EngineManagement = ({ filters, ...props }) => {
  const isMobile = useMobile()

  return (
    <CustomScrollbars>
      <div className="p-4 w-100">
        <div className={cx(classes.widgets, 'mx-n2')}>
          {Object.values(Runners).map(rnr => (
            <div className={cx(classes.widget, 'px-2', {
              [classes.widgetMobile]: isMobile,
            })}
            >
              <RunnersWidget
                entityType={rnr.type}
                title={rnr.title}
                titleIcon={rnr.icon}
              />
            </div>
          ))}
        </div>
      </div>
    </CustomScrollbars>
  )
}

export default EngineManagement
